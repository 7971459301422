/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  AddSimulatorDatabase, createSimulatorUserEmail,
  deleteSimulatorDatabase, loginWithSimulatorUserEmail, updateSimulatorDatabase,
  createSimulatorReset, verifySimulatorReset, createSimulatorDatabaseBucket, changePasswordSimulatorUser,
  AddSimulatorCustomDatabase,
  verifySimulatorVerify,
  updateSimulatorUserProject,
  UpdateSimulatorCustomDatabase,
  AddSimulatorBucketCustomDatabase,
} from 'api-lofty';
import React, {useEffect} from 'react';
import toastr from 'toastr';
import html2pdf from 'html2pdf.js';
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
import {searchComponentsBySlug} from '../util/searchPage';
import EditorJs from './EditorJs';
import EditorJsReadOnly from './EditorJsReadOnly';
import {
  componentButton,
  componentDiv, componentForm, componentInput,
  componentLabel, componentSelect, componentTBody, componentTable, componentTd, componentTh, componentThead, componentTr, manageClasses, slugLoftyEqualTo,
  componentOlList, componentListItem, componentUserAuth, componentDynImage, componentAnimatedScrollContainer, componentDynamicVideo,
  componentGoogleIcon,
} from '../util/componentsUtil';
import logicSimulatorFrontendCustom from '../util/BlocklyLogic';
import OptionsDatabase from './OptionsDatabase';
import CalendarDynamic from './CalendarDynamic';
import ItemCalendar from './ItemCalendarDynamic';
import 'aos/dist/aos.css';
import CarouselDynamic from './CarouselDynamic';
import CartCarouselDynamic from './CartCarouselDynamic';
import DynamicDropdownMenu from './DynamicDropdownMenu';
import SingleCarouselDynamic from './SingleCarouselDynamic';
import GoogleLoginComponent from './GoogleLoginComponent';
import CountdownComponent from './CountdownComponent';
import LeafMapComponent from './LeafMapComponent';
// import '../style/modalStyle.css';

function typeOfObjectReader(objData, nameFiledReader, idGetEndpoint, dataGenerate, localVars, globalVars) {
  // console.log(objData, nameFiledReader, dataGenerate, idGetEndpoint);
  if (objData?.latitude || objData?.longitude) {
    const dataDelimiter = `${objData?.latitude}, ${objData?.longitude}`;
    // const dataDelimiter = 'N/A';
    return dataDelimiter;
  } else {
    const endpoint = dataGenerate.endpoints?.find((item) => item._id === idGetEndpoint);
    // console.log(endpoint);
    if (endpoint) {
      if (endpoint.typeFunction === 'list' || endpoint.typeFunction === 'one') {
        const field = dataGenerate.fieldsDatabase?.find((item) => (
          item.name === nameFiledReader && item.type === 'number'
          && item.idDatabase === endpoint.idDatabase && item.typeNumber === 'money'
        ));
        if (field) {
          return parseFloat(objData)?.toLocaleString('en-US', {minimumFractionDigits: 2});
        }
      } else if (endpoint.typeFunction === 'customOne' || endpoint.typeFunction === 'customList') {
        const field = dataGenerate.fieldsDatabase?.find((item) => (
          item.name === nameFiledReader && item.type === 'number'
          && item.idDatabase === endpoint.responseDatabase && item.typeNumber === 'money'
        ));
        if (field) {
          return parseFloat(objData)?.toLocaleString('en-US', {minimumFractionDigits: 2});
        }
      }
    }
    const variableLocal = dataGenerate?.statePage?.find((item) => (
      item._id === localVars
    ));
    if (variableLocal) {
      const field = dataGenerate.fieldsDatabase?.find((item) => (
        item.name === nameFiledReader && item.type === 'number'
        && item.idDatabase === variableLocal.idDatabase && item.typeNumber === 'money'
      ));
      if (field) {
        return parseFloat(objData)?.toLocaleString('en-US', {minimumFractionDigits: 2});
      }
    }
    const variableGlobal = dataGenerate?.variableGlobal?.find((item) => (
      item._id === globalVars
    ));
    if (variableGlobal) {
      const field = dataGenerate.fieldsDatabase?.find((item) => (
        item.name === nameFiledReader && item.type === 'number'
        && item.idDatabase === variableGlobal.idDatabase && item.typeNumber === 'money'
      ));
      if (field) {
        return parseFloat(objData)?.toLocaleString('en-US', {minimumFractionDigits: 2});
      }
    }
    return objData;
  }
}

function validDesignation(objData, objReader) {
  try {
    if (typeof objReader[objData] !== 'object') {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

function searchMyValue(id, infoData) {
  // console.log(id);
  const resData = infoData?.find((index) => (index._id === id));
  if (resData?.state) {
    return resData.state;
  }
  return '';
}

function findArrayLength(id, infoData) {
  const resData = infoData?.find((index) => (index._id === id));
  if (resData?.state?.length) {
    return resData.state.length;
  }
  return 0;
}

function searchBooleanState(id, infoData) {
  // console.log(id);
  const resData = infoData?.find((index) => (index._id === id));
  if (resData?.state) {
    return resData.state;
  } else if (resData) {
    return false;
  }
  return 'No Variable';
}

function findVariableExists(id, localData, globalData) {
  const localSeeker = localData.some((index) => (index._id === id));
  const globalSeeker = globalData.some((index) => (index._id === id));
  if (localSeeker) {
    return 'isLocal';
  }
  if (globalSeeker) {
    return 'isGlobal';
  }
  return '';
}

function updateLocalVariable(id, value, localData, setLocalState, refurbishEndpoint, variableForEndpoint) {
  const updateToData = [...localData];
  const captureObject = updateToData?.find((obj) => (obj._id === id));
  if (captureObject) {
    // captureObject.state = value;
    const updateItems = localData.map((item) => (
      item._id === id ? {...item, state: value} : item
    ));
    setLocalState(updateItems);
  }
  const hasVariableInEndpoint = variableForEndpoint?.find((obj) => (obj?.variable?._id === id));
  if (hasVariableInEndpoint) {
    refurbishEndpoint(hasVariableInEndpoint.endpoint._id, id);
  }
}

function updateGlobalVariable(id, value, globalData, setGlobalState, refurbishEndpoint, variableForEndpoint) {
  const updateToData = [...globalData];
  const captureObject = updateToData?.find((obj) => (obj._id === id));
  if (captureObject) {
    // captureObject.state = value;
    const updateItems = globalData.map((item) => (
      item._id === id ? {...item, state: value} : item
    ));
    setGlobalState(updateItems);
  }
  const hasVariableInEndpoint = variableForEndpoint?.find((obj) => (obj.variable._id === id));
  if (hasVariableInEndpoint) {
    refurbishEndpoint(hasVariableInEndpoint.endpoint._id, id);
  }
}

function cartStateUpdate(id, originalValue, value, globalData, setGlobalState, localData, setLocalData, fromLocation) {
  if (fromLocation === 'global') {
    const updateToData = [...globalData];
    const captureObjectIndex = updateToData?.findIndex((obj) => (obj._id === id));
    if (captureObjectIndex !== -1) {
      // Analyze Possible existing item to transmute
      const stateOfVar = globalData[captureObjectIndex].state;
      const findIndexOfOrigin = stateOfVar.findIndex((item) => (item?.relationdata?._id === originalValue.relationdata._id));
      if (findIndexOfOrigin === -1) {
        return;
      }
      const findIndexOfUpdate = stateOfVar.findIndex((item) => (item?.relationdata?._id === value._id));
      if (findIndexOfUpdate !== -1) {
        stateOfVar[findIndexOfUpdate].quantity += stateOfVar[findIndexOfOrigin].quantity;
        stateOfVar.splice(findIndexOfOrigin, 1);
      } else {
        stateOfVar[findIndexOfOrigin].relationdata = value;
      }

      const updateItems = globalData.map((item, i) => (
        i === captureObjectIndex ? {...item, state: [...stateOfVar]} : item
      ));
      setGlobalState(updateItems);
    }
  }
  if (fromLocation === 'local') {
    const updateToData = [...localData];
    const captureObjectIndex = updateToData?.findIndex((obj) => (obj._id === id));
    if (captureObjectIndex !== -1) {
      // Analyze Possible existing item to transmute
      const stateOfVar = localData[captureObjectIndex].state;
      const findIndexOfOrigin = stateOfVar.findIndex((item) => (item?.relationdata?._id === originalValue.relationdata._id));
      if (findIndexOfOrigin === -1) {
        return;
      }
      const findIndexOfUpdate = stateOfVar.findIndex((item) => (item?.relationdata?._id === value._id));
      if (findIndexOfUpdate !== -1) {
        stateOfVar[findIndexOfUpdate].quantity += stateOfVar[findIndexOfOrigin].quantity;
        stateOfVar.splice(findIndexOfOrigin, 1);
      } else {
        stateOfVar[findIndexOfOrigin].relationdata = value;
      }

      const updateItems = localData.map((item, i) => (
        i === captureObjectIndex ? {...item, state: [...stateOfVar]} : item
      ));
      setLocalData(updateItems);
    }
  }
}

function addItemToArray(idArray, valueToAdd, localData, globalData, setLocalState, setGlobalState, hasRelation) {
  const findLocation = findVariableExists(idArray, localData, globalData);
  if (findLocation === 'isLocal') {
    const updateToData = [...localData];
    const captureObject = updateToData?.find((obj) => (obj._id === idArray));
    const toAddNewInfo = captureObject?.state || [];
    if (captureObject.type === 'cartArray') {
      if (valueToAdd.length > 1) {
        // Validate if has relation
        if (hasRelation) {
          const seekLocation = findVariableExists(hasRelation, localData, globalData);
          let valueOfState = '';
          if (seekLocation === 'isLocal') {
            valueOfState = searchMyValue(hasRelation, localData);
          } else if (seekLocation === 'isGlobal') {
            valueOfState = searchMyValue(hasRelation, globalData);
          }
          if ((!valueOfState || valueOfState === '') && !captureObject?.isRelationOptional) {
            alert('Error. Faltan datos para agregar al carrito');
            return;
          }
          valueToAdd.forEach((index) => {
            let checkExists = -1;
            if (captureObject.isRelationOptional) {
              checkExists = toAddNewInfo.findIndex((item) => (item.id === index._id));
            } else {
              checkExists = toAddNewInfo.findIndex((item) => (item.id === index._id && item?.relationdata?._id === valueOfState?._id));
            }
            if (checkExists !== -1) {
              toAddNewInfo[checkExists].quantity += 1;
            } else {
              toAddNewInfo.push({
                cartitem: index,
                id: index._id,
                quantity: 1,
                // relationid: valueOfState?._id,
                relationdata: valueOfState,
              });
            }
            // Var label, ... state
          });
        } else {
          valueToAdd.forEach((index) => {
            const checkExists = toAddNewInfo.findIndex((item) => item.id === index._id);
            if (checkExists !== -1) {
              toAddNewInfo[checkExists].quantity += 1;
            } else {
              toAddNewInfo.push({cartitem: index, id: index._id, quantity: 1});
            }
          });
        }
      } else {
        // VERIFY IF HAS RELATION
        if (hasRelation) {
          const seekLocation = findVariableExists(hasRelation, localData, globalData);
          let valueOfState = '';
          if (seekLocation === 'isLocal') {
            valueOfState = searchMyValue(hasRelation, localData);
          } else if (seekLocation === 'isGlobal') {
            valueOfState = searchMyValue(hasRelation, globalData);
          }
          if ((!valueOfState || valueOfState === '') && !captureObject?.isRelationOptional) {
            alert('Error. Faltan datos para agregar al carrito');
            return;
          }
          let checkExists = -1;
          if (captureObject.isRelationOptional) {
            checkExists = toAddNewInfo.findIndex((item) => (item.id === valueToAdd._id));
          } else {
            checkExists = toAddNewInfo.findIndex((item) => (item.id === valueToAdd._id && item?.relationdata?._id === valueOfState?._id));
          }
          if (checkExists !== -1) {
            toAddNewInfo[checkExists].quantity += 1;
          } else {
            toAddNewInfo.push({
              cartitem: valueToAdd,
              id: valueToAdd._id,
              quantity: 1,
              relationdata: valueOfState,
            });
          }
        } else {
          const checkExists = toAddNewInfo.findIndex((item) => item.id === valueToAdd._id);
          if (checkExists !== -1) {
            toAddNewInfo[checkExists].quantity += 1;
          } else {
            toAddNewInfo.push({cartitem: valueToAdd, id: valueToAdd._id, quantity: 1});
          }
        }
      }
    } else {
      if (valueToAdd.length > 1) {
        valueToAdd.forEach((index) => toAddNewInfo.push(index));
      } else {
        toAddNewInfo.push(valueToAdd);
      }
    }
    if (captureObject) {
      // captureObject.state = toAddNewInfo;
      const updateItems = localData.map((item) => (
        item._id === idArray ? {...item, state: toAddNewInfo} : item
      ));
      setLocalState(updateItems);
    }
  } else {
    const updateToData = [...globalData];
    const captureObject = updateToData?.find((obj) => (obj._id === idArray));
    const toAddNewInfo = captureObject?.state || [];
    if (captureObject.typeValue === 'cartArray') {
      if (valueToAdd.length > 1) {
        // Validate if has relation
        if (hasRelation) {
          const seekLocation = findVariableExists(hasRelation, localData, globalData);
          let valueOfState = '';
          if (seekLocation === 'isLocal') {
            valueOfState = searchMyValue(hasRelation, localData);
          } else if (seekLocation === 'isGlobal') {
            valueOfState = searchMyValue(hasRelation, globalData);
          }
          if ((!valueOfState || valueOfState === '') && !captureObject?.isRelationOptional) {
            alert('Error. Faltan datos para agregar al carrito');
            return;
          }
          valueToAdd.forEach((index) => {
            let checkExists = -1;
            if (captureObject.isRelationOptional) {
              checkExists = toAddNewInfo.findIndex((item) => (item.id === index._id));
            } else {
              checkExists = toAddNewInfo.findIndex((item) => (item.id === index._id && item?.relationdata?._id === valueOfState?._id));
            }
            if (checkExists !== -1) {
              toAddNewInfo[checkExists].quantity += 1;
            } else {
              toAddNewInfo.push({
                cartitem: index,
                id: index._id,
                quantity: 1,
                // relationid: valueOfState?._id,
                relationdata: valueOfState,
              });
            }
            // Var label, ... state
          });
        } else {
          valueToAdd.forEach((index) => {
            const checkExists = toAddNewInfo.findIndex((item) => item.id === index._id);
            if (checkExists !== -1) {
              toAddNewInfo[checkExists].quantity += 1;
            } else {
              toAddNewInfo.push({cartitem: index, id: index._id, quantity: 1});
            }
          });
        }
      } else {
        // VERIFY IF HAS RELATION
        if (hasRelation) {
          const seekLocation = findVariableExists(hasRelation, localData, globalData);
          let valueOfState = '';
          if (seekLocation === 'isLocal') {
            valueOfState = searchMyValue(hasRelation, localData);
          } else if (seekLocation === 'isGlobal') {
            valueOfState = searchMyValue(hasRelation, globalData);
          }
          if ((!valueOfState || valueOfState === '') && !captureObject?.isRelationOptional) {
            alert('Error. Faltan datos para agregar al carrito');
            return;
          }
          let checkExists = -1;
          if (captureObject.isRelationOptional) {
            checkExists = toAddNewInfo.findIndex((item) => (item.id === valueToAdd._id));
          } else {
            checkExists = toAddNewInfo.findIndex((item) => (item.id === valueToAdd._id && item?.relationdata?._id === valueOfState?._id));
          }
          if (checkExists !== -1) {
            toAddNewInfo[checkExists].quantity += 1;
          } else {
            toAddNewInfo.push({
              cartitem: valueToAdd,
              id: valueToAdd._id,
              quantity: 1,
              relationdata: valueOfState,
            });
          }
        } else {
          const checkExists = toAddNewInfo.findIndex((item) => item.id === valueToAdd._id);
          if (checkExists !== -1) {
            toAddNewInfo[checkExists].quantity += 1;
          } else {
            toAddNewInfo.push({cartitem: valueToAdd, id: valueToAdd._id, quantity: 1});
          }
        }
      }
    } else {
      if (valueToAdd.length > 1) {
        // toAddNewInfo = valueToAdd;
        valueToAdd.forEach((index) => toAddNewInfo.push(index));
      } else {
        toAddNewInfo.push(valueToAdd);
      }
    }
    if (captureObject) {
      captureObject.state = toAddNewInfo;
      const updateItems = globalData.map((item) => (
        item._id === idArray ? {...item, state: toAddNewInfo} : item
      ));
      // setLocalState(updateItems);
      setGlobalState(updateItems);
    }
  }
}

function setDataOfVariable(idVariable, valueToAdd, localData, globalData, setLocalState, setGlobalState) {
  const findLocation = findVariableExists(idVariable, localData, globalData);
  if (findLocation === 'isLocal') {
    const updateToData = [...localData];
    const captureObject = updateToData?.find((obj) => (obj._id === idVariable));
    if (captureObject) {
      // captureObject.state = valueToAdd;
      const updateItems = localData.map((item) => (
        item._id === idVariable ? {...item, state: valueToAdd} : item
      ));
      setLocalState(updateItems);
    }
  } else if (findLocation === 'isGlobal') {
    const updateToData = [...globalData];
    const captureObject = updateToData?.find((obj) => (obj._id === idVariable));
    if (captureObject) {
      // captureObject.state = valueToAdd;
      const updateItems = globalData.map((item) => (
        item._id === idVariable ? {...item, state: valueToAdd} : item
      ));
      setGlobalState(updateItems);
    }
  }
}

// Hamburger
function initHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}

function haveContent(cadena) {
  const regex = /[a-zA-Z0-9]+/;
  return regex.test(cadena);
}

function initModal(component) {
  try {
    const modalContainer = document.getElementById(component.attributes.id);
    modalContainer.style.cssText = 'block !important';
    addEventListener('click', (event) => {
      if (event.target === modalContainer) {
        modalContainer.style.cssText = 'none !important';
      }
    });
  } catch (error) {
    //
  }
}

// function handleModalInit(idCaller) {
//   try {
//     const modalContainer = document.getElementById(idCaller);
//     modalContainer.style.cssText = 'block !important';
//     addEventListener('click', (event) => {
//       if (event.target === modalContainer) {
//         modalContainer.style.cssText = 'none !important';
//       }
//     });
//   } catch (error) {
//     //
//   }
// }

async function handleVerifyEmailSimulator(idProject, token, tokenVerify) {
  try {
    await verifySimulatorVerify({token, idProject, tokenVerify});
    alert('Se ha verificado el correo');
  } catch (error) {
    alert(`Error al verificar el correo. \n ${error.info}`);
  }
}

function handleQuantityItemFromCart(idArray, idItem, localData, globalData, setLocalState, setGlobalState, typeOfHandle) {
  try {
    const findLocation = findVariableExists(idArray, localData, globalData);
    if (findLocation === 'isLocal') {
      const updateToData = [...localData];
      const captureObject = updateToData?.find((obj) => (obj._id === idArray));
      const toAddNewInfo = captureObject?.state || [];
      if (captureObject.type === 'cartArray') {
        const checkExists = toAddNewInfo.findIndex((item) => item.id === idItem);
        if (checkExists !== -1) {
          if (typeOfHandle === 'decrease') {
            setLocalState((prevData) => prevData.map((entry) => (entry._id === idArray && entry.type === 'cartArray'
              ? {
                ...entry,
                state: entry.state
                  .map((item) => (item.id === idItem
                    ? {...item, quantity: item.quantity - 1}
                    : item))
                  .filter((item) => item.quantity > 0),
              }
              : entry)));
          }
          if (typeOfHandle === 'increase') {
            setLocalState((prevData) => prevData.map((entry) => (entry._id === idArray && entry.type === 'cartArray'
              ? {
                ...entry,
                state: entry.state
                  .map((item) => (item.id === idItem
                    ? {...item, quantity: item.quantity + 1}
                    : item))
                  .filter((item) => item.quantity > 0),
              }
              : entry)));
          }
        }
      }
    } else {
      const updateToData = [...globalData];
      const captureObject = updateToData?.find((obj) => (obj._id === idArray));
      const toAddNewInfo = captureObject?.state || [];
      if (captureObject.typeValue === 'cartArray') {
        const checkExists = toAddNewInfo.findIndex((item) => item.id === idItem);
        if (checkExists !== -1) {
          if (typeOfHandle === 'decrease') {
            setGlobalState((prevData) => prevData.map((entry) => (entry._id === idArray && entry.typeValue === 'cartArray'
              ? {
                ...entry,
                state: entry.state
                  .map((item) => (item.id === idItem
                    ? {...item, quantity: item.quantity - 1}
                    : item))
                  .filter((item) => item.quantity > 0),
              }
              : entry)));
          }
          if (typeOfHandle === 'increase') {
            setGlobalState((prevData) => prevData.map((entry) => (entry._id === idArray && entry.typeValue === 'cartArray'
              ? {
                ...entry,
                state: entry.state
                  .map((item) => (item.id === idItem
                    ? {...item, quantity: item.quantity + 1}
                    : item))
                  .filter((item) => item.quantity > 0),
              }
              : entry)));
          }
        }
      }
    }
  } catch (error) {
    alert('No se logro actualizar la informacion del carrito');
  }
}

//
function DynamicComponent({
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token, deletetokenApp,
  dataGenerate, relationParamData,
  // para el plugin del calendario
  changeNextMonth, activeMonth, changeHour,
  changeLastMonth, now, selectDate, listHours,
  submitCalendar, userAuthVariables,
  // individual modal
  // idModalHandler,
  user,
  // para saber si se usa el usuario autenticado para agregar un dato
  useCurrentUserAddDatabase,
  // variable para saber si el formulario tiene multiples archivos
  allowMultipleAttachment,
  // para el plugin de 3 carruseles de productos
  idGetEnpointSwiper1,
  idGetEnpointSwiper2,
  idGetEnpointSwiper3,
  userInfo,
  databaseSelectionForFields,
  // VARIABLE OR STATES
  globalVariables,
  setGlobalState,
  localState,
  setLocalState,
  localVars,
  globalVars,
  // Input With Variables
  inputStateVariable,
  // For Read Db with dbField data
  rootReaderData,
  refurbishEndpoint,
  variableForEndpoint,
  responseAddUpdateDb,
  // Cart Info
  cartParamData,
  // Carousel Flag
  carouselOriginState,
}) {
  // componente div
  // console.log(component.sluglofty, component.slugLofty, addTokenApp, 'impresion de componente redux');
  if (componentDiv({component})) {
    if (slugLoftyEqualTo(component, 'cartItemHandler')) {
      if (!rootReaderData || !objectReader) {
        return null;
      }
      let idFromOrigin = '';
      if (localVars) {
        idFromOrigin = localVars;
      }
      if (globalVars) {
        idFromOrigin = globalVars;
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
          component?.components?.map((index) => {
            if (index?.sluglofty === 'reducerItemHandler') {
              return (
                <button
                  name={index.attributes?.name}
                  id={index.attributes?.id}
                  className={manageClasses(index)}
                  onClick={() => handleQuantityItemFromCart(idFromOrigin, objectReader._id, localState, globalVariables, setLocalState, setGlobalState, 'decrease')}
                >
                  {index?.components?.map((item) => (
                    <DynamicComponent
                      idProject={idProject}
                      component={item}
                      dataEndpoints={dataEndpoints}
                      objectReader={objectReader}
                      params={params}
                      idDeleteEndpoint={idDeleteEndpoint}
                      idGetEndpoint={idGetEndpoint}
                      idPostEndpoint={idPostEndpoint}
                      idPutEndpoint={idPutEndpoint}
                      nameFiledReader={nameFiledReader}
                      relationParam={relationParam}
                      deletetokenApp={deletetokenApp}
                      dataGenerate={dataGenerate}
                  // plugin del calnedario
                      activeMonth={activeMonth}
                      changeLastMonth={changeLastMonth}
                      changeNextMonth={changeNextMonth}
                      listHours={listHours}
                      now={now}
                      selectDate={selectDate}
                      changeHour={changeHour}
                      submitCalendar={submitCalendar}
                      addTokenApp={addTokenApp}
                      addUserApp={addUserApp}
                      user={user}
                      useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                      token={token}
                      allowMultipleAttachment={allowMultipleAttachment}
                      idGetEnpointSwiper1={idGetEnpointSwiper1}
                      idGetEnpointSwiper2={idGetEnpointSwiper2}
                      idGetEnpointSwiper3={idGetEnpointSwiper3}
                      relationParamData={relationParamData}
                      userInfo={userInfo}
                      databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                      globalVariables={globalVariables}
                      setGlobalState={setGlobalState}
                      localState={localState}
                      setLocalState={setLocalState}
                      globalVars={globalVars}
                      localVars={localVars}
                  // Input With Variables
                      inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                      rootReaderData={rootReaderData}
                      refurbishEndpoint={refurbishEndpoint}
                      variableForEndpoint={variableForEndpoint}
                      responseAddUpdateDb={responseAddUpdateDb}
                      cartParamData={cartParamData}
                      carouselOriginState={carouselOriginState}
                    />
                  ))}
                </button>
              );
            }
            if (index?.sluglofty === 'quantityItemHandler') {
              const textSeeker = index?.components?.find((items) => (items.type === 'textnode'));
              return (
                <div className={manageClasses(index)} id={index.attributes?.id}>
                  {`${textSeeker?.content || 'Qty: '} ${rootReaderData?.quantity}`}
                </div>
              );
            }
            if (index.sluglofty === 'increaseItemHandler') {
              return (
                <button
                  name={index.attributes?.name}
                  id={index.attributes?.id}
                  className={manageClasses(index)}
                  onClick={() => handleQuantityItemFromCart(idFromOrigin, objectReader._id, localState, globalVariables, setLocalState, setGlobalState, 'increase')}
                >
                  {index?.components?.map((item) => (
                    <DynamicComponent
                      idProject={idProject}
                      component={item}
                      dataEndpoints={dataEndpoints}
                      objectReader={objectReader}
                      params={params}
                      idDeleteEndpoint={idDeleteEndpoint}
                      idGetEndpoint={idGetEndpoint}
                      idPostEndpoint={idPostEndpoint}
                      idPutEndpoint={idPutEndpoint}
                      nameFiledReader={nameFiledReader}
                      relationParam={relationParam}
                      deletetokenApp={deletetokenApp}
                      dataGenerate={dataGenerate}
                  // plugin del calnedario
                      activeMonth={activeMonth}
                      changeLastMonth={changeLastMonth}
                      changeNextMonth={changeNextMonth}
                      listHours={listHours}
                      now={now}
                      selectDate={selectDate}
                      changeHour={changeHour}
                      submitCalendar={submitCalendar}
                      addTokenApp={addTokenApp}
                      addUserApp={addUserApp}
                      user={user}
                      useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                      token={token}
                      allowMultipleAttachment={allowMultipleAttachment}
                      idGetEnpointSwiper1={idGetEnpointSwiper1}
                      idGetEnpointSwiper2={idGetEnpointSwiper2}
                      idGetEnpointSwiper3={idGetEnpointSwiper3}
                      relationParamData={relationParamData}
                      userInfo={userInfo}
                      databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                      globalVariables={globalVariables}
                      setGlobalState={setGlobalState}
                      localState={localState}
                      setLocalState={setLocalState}
                      globalVars={globalVars}
                      localVars={localVars}
                  // Input With Variables
                      inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                      rootReaderData={rootReaderData}
                      refurbishEndpoint={refurbishEndpoint}
                      variableForEndpoint={variableForEndpoint}
                      responseAddUpdateDb={responseAddUpdateDb}
                      cartParamData={cartParamData}
                      carouselOriginState={carouselOriginState}
                    />
                  ))}
                </button>
              );
            }
            return null;
          })
        }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'countdownComponent')) {
      return (
        <CountdownComponent
          component={component}
        />
      );
    }
    if (slugLoftyEqualTo(component, 'dropdownMenuContainer')) {
      return (
        <DynamicDropdownMenu
          idProject={idProject}
          component={component}
          dataEndpoints={dataEndpoints}
          objectReader={objectReader}
          params={params}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idPutEndpoint={idPutEndpoint}
          nameFiledReader={nameFiledReader}
          relationParam={relationParam}
          deletetokenApp={deletetokenApp}
          dataGenerate={dataGenerate}
          // plugin del calnedario
          activeMonth={activeMonth}
          changeLastMonth={changeLastMonth}
          changeNextMonth={changeNextMonth}
          listHours={listHours}
          now={now}
          selectDate={selectDate}
          changeHour={changeHour}
          submitCalendar={submitCalendar}
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          user={user}
          useCurrentUserAddDatabase={useCurrentUserAddDatabase}
          token={token}
          allowMultipleAttachment={allowMultipleAttachment}
          idGetEnpointSwiper1={idGetEnpointSwiper1}
          idGetEnpointSwiper2={idGetEnpointSwiper2}
          idGetEnpointSwiper3={idGetEnpointSwiper3}
          relationParamData={relationParamData}
          userInfo={userInfo}
          databaseSelectionForFields={databaseSelectionForFields}
          // STATES
          globalVariables={globalVariables}
          setGlobalState={setGlobalState}
          localState={localState}
          setLocalState={setLocalState}
          globalVars={globalVars}
          localVars={localVars}
          // Input With Variables
          inputStateVariable={inputStateVariable}
          // For Read Db with dbField data
          rootReaderData={rootReaderData}
          refurbishEndpoint={refurbishEndpoint}
          variableForEndpoint={variableForEndpoint}
          userAuthVariables={userAuthVariables}
          responseAddUpdateDb={responseAddUpdateDb}
          cartParamData={cartParamData}
          carouselOriginState={carouselOriginState}
        />
      );
    }
    if (slugLoftyEqualTo(component, 'carouselCartViewer')) {
      return (
        <CartCarouselDynamic
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          component={component}
          dataEndpoints={dataEndpoints}
          dataGenerate={dataGenerate}
          globalVariables={globalVariables}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idProject={idProject}
          idPutEndpoint={idPutEndpoint}
          localState={localState}
          nameFiledReader={nameFiledReader}
          params={params}
          refurbishEndpoint={refurbishEndpoint}
          relationParam={relationParam}
          setGlobalState={setGlobalState}
          setLocalState={setLocalState}
          token={token}
          variableForEndpoint={variableForEndpoint}
        />
      );
    }
    if (slugLoftyEqualTo(component, 'singleDynamicCarouselComponent')) {
      const getCarouselListEndpoints = dataEndpoints?.find((index) => (index.id === component?.endpointSelect && index.typeEndpoint === 'singleCarouselList'));
      if (getCarouselListEndpoints) {
        return (
          <SingleCarouselDynamic
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            component={component}
            carouselComponents={component}
            carouselData={getCarouselListEndpoints}
            dataEndpoints={dataEndpoints}
            dataGenerate={dataGenerate}
            globalVariables={globalVariables}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={component?.endpointSelect}
            idPostEndpoint={idPostEndpoint}
            idProject={idProject}
            idPutEndpoint={idPutEndpoint}
            localState={localState}
            nameFiledReader={nameFiledReader}
            objectReader={getCarouselListEndpoints}
            params={params}
            refurbishEndpoint={refurbishEndpoint}
            relationParam={relationParam}
            setGlobalState={setGlobalState}
            setLocalState={setLocalState}
            token={token}
            variableForEndpoint={variableForEndpoint}
          />
        );
      }
      return null;
    }
    if (slugLoftyEqualTo(component, 'threeSwiperComponent')) {
      if (component?.attributes?.paramCarouselData) {
        const getAllCustomEndpoints = dataEndpoints?.filter((index) => (index.id === component?.endpointSelect && index.typeEndpoint === 'carouselCustom'));
        if (getAllCustomEndpoints && getAllCustomEndpoints.length > 0) {
          const getOnlyCarousels = component?.components?.filter((model) => (
            model.sluglofty === 'reactCarouselComponent'
            || model.sluglofty === 'booleanDynamicComponent'
          ));
          return (
            <CarouselDynamic
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              component={component}
              carouselComponents={getOnlyCarousels}
              carouselData={getAllCustomEndpoints}
              dataGenerate={dataGenerate}
              dataEndpoints={dataEndpoints}
              globalVariables={globalVariables}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idProject={idProject}
              idPutEndpoint={idPutEndpoint}
              localState={localState}
              nameFiledReader={nameFiledReader}
              objectReader={getAllCustomEndpoints}
              params={params}
              refurbishEndpoint={refurbishEndpoint}
              relationParam={relationParam}
              setGlobalState={setGlobalState}
              setLocalState={setLocalState}
              token={token}
              variableForEndpoint={variableForEndpoint}
            />
          );
        }
        return null;
      }
      const getCarouselListEndpoints = dataEndpoints?.find((index) => (index.id === component?.endpointSelect && index.typeEndpoint === 'carouselList'));
      if (getCarouselListEndpoints) {
        const getOnlyCarousels = component?.components?.filter((model) => (
          model.sluglofty === 'reactCarouselComponent'
          || model.sluglofty === 'booleanDynamicComponent'
        ));
        if (getOnlyCarousels && getOnlyCarousels.length > 0) {
          const repeatReader = [];
          getOnlyCarousels.forEach(() => {
            repeatReader.push(getCarouselListEndpoints);
          });
          return (
            <CarouselDynamic
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              component={component}
              carouselComponents={getOnlyCarousels}
              dataGenerate={dataGenerate}
              carouselData={repeatReader}
              dataEndpoints={dataEndpoints}
              globalVariables={globalVariables}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idProject={idProject}
              idPutEndpoint={idPutEndpoint}
              localState={localState}
              nameFiledReader={nameFiledReader}
              objectReader={repeatReader}
              params={params}
              refurbishEndpoint={refurbishEndpoint}
              relationParam={relationParam}
              setGlobalState={setGlobalState}
              setLocalState={setLocalState}
              token={token}
              variableForEndpoint={variableForEndpoint}
            />
          );
        }
        return null;
      }
      return null;
    }
    if (component.sluglofty === 'itemCartListEndpointDatabase') {
      if (!objectReader || objectReader?.length <= 0) {
        return null;
      }
      let idFromOrigin = '';
      let locationOfData = '';
      if (localVars) {
        idFromOrigin = localVars;
        locationOfData = 'local';
      } else if (globalVars) {
        idFromOrigin = globalVars;
        locationOfData = 'global';
      }
      return (
        <>
          {
            objectReader?.map((data) => (
              <div
                className={manageClasses(component)}
                style={{cursor: 'pointer'}}
                id={component.attributes?.id}
                onClick={() => cartStateUpdate(idFromOrigin, rootReaderData, data, globalVariables, setGlobalState, localState, setLocalState, locationOfData)}
              >
                {
                  component?.components?.map((item) => (
                    <DynamicComponent
                      idProject={idProject}
                      component={item}
                      dataEndpoints={dataEndpoints}
                      objectReader={data}
                      params={params}
                      idDeleteEndpoint={idDeleteEndpoint}
                      idGetEndpoint={idGetEndpoint}
                      idPostEndpoint={idPostEndpoint}
                      idPutEndpoint={idPutEndpoint}
                      nameFiledReader={nameFiledReader}
                      relationParam={relationParam}
                      deletetokenApp={deletetokenApp}
                      dataGenerate={dataGenerate}
                      // plugin del calnedario
                      activeMonth={activeMonth}
                      changeLastMonth={changeLastMonth}
                      changeNextMonth={changeNextMonth}
                      listHours={listHours}
                      now={now}
                      selectDate={selectDate}
                      changeHour={changeHour}
                      submitCalendar={submitCalendar}
                      addTokenApp={addTokenApp}
                      addUserApp={addUserApp}
                      user={user}
                      useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                      token={token}
                      allowMultipleAttachment={allowMultipleAttachment}
                      idGetEnpointSwiper1={idGetEnpointSwiper1}
                      idGetEnpointSwiper2={idGetEnpointSwiper2}
                      idGetEnpointSwiper3={idGetEnpointSwiper3}
                      relationParamData={relationParamData}
                      userInfo={userInfo}
                      databaseSelectionForFields={databaseSelectionForFields}
                      // STATES
                      globalVariables={globalVariables}
                      setGlobalState={setGlobalState}
                      localState={localState}
                      setLocalState={setLocalState}
                      globalVars={globalVars}
                      localVars={localVars}
                      // Input With Variables
                      inputStateVariable={inputStateVariable}
                      // For Read Db with dbField data
                      rootReaderData={data}
                      refurbishEndpoint={refurbishEndpoint}
                      variableForEndpoint={variableForEndpoint}
                      responseAddUpdateDb={responseAddUpdateDb}
                      cartParamData={cartParamData}
                      carouselOriginState={carouselOriginState}
                    />
                  ))
                }
              </div>
            ))
          }
        </>
      );
    }
    if (component.sluglofty === 'dynamicMapComponent') {
      console.log('entering section of map');
      const findMainData = dataEndpoints?.find((index) => (index.id === component?.endpointSelect && index.type === 'mapEndpointData'));
      if (findMainData) {
        return (
          <LeafMapComponent
            component={component}
            maindata={findMainData}
            dataGenerate={dataGenerate}
          />
        );
      }
      return null;
    }
    if (component.sluglofty === 'cartRelationData') {
      if (!rootReaderData || rootReaderData?.length <= 0) {
        return null;
      }
      let withParams = false;
      if (params?.length > 0) {
        withParams = true;
      }
      // const findAllRelatedCartEndpoints = dataEndpoints?.find((index) => (index.id === idGetEndpoint && index.typeEndpoint === 'cartCarousel'));
      if (withParams) {
        const findRelationData = dataEndpoints?.filter((index) => (index.id === idGetEndpoint && index.type === 'cartCarouselParams'));
        const findMainData = findRelationData?.find((index) => (index.fromOriginListId === rootReaderData.id));
        if (findMainData) {
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
                component?.components?.map((item) => (
                  <DynamicComponent
                    idProject={idProject}
                    component={item}
                    dataEndpoints={dataEndpoints}
                    objectReader={findMainData?.data?.data}
                    params={params}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idGetEndpoint={idGetEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    nameFiledReader={nameFiledReader}
                    relationParam={relationParam}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    token={token}
                    allowMultipleAttachment={allowMultipleAttachment}
                    idGetEnpointSwiper1={idGetEnpointSwiper1}
                    idGetEnpointSwiper2={idGetEnpointSwiper2}
                    idGetEnpointSwiper3={idGetEnpointSwiper3}
                    relationParamData={relationParamData}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    globalVars={globalVars}
                    localVars={localVars}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={rootReaderData}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                    responseAddUpdateDb={responseAddUpdateDb}
                    cartParamData={cartParamData}
                    carouselOriginState={carouselOriginState}
                  />
                ))
              }
            </div>
          );
        }
      } else {
        const findMainData = dataEndpoints?.find((index) => (index.id === idGetEndpoint && (index.type === 'cartCarouselSimple' || index.type === 'cartCarouselList')));
        if (findMainData) {
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
                component?.components?.map((item) => (
                  <DynamicComponent
                    idProject={idProject}
                    component={item}
                    dataEndpoints={dataEndpoints}
                    objectReader={findMainData?.data?.data}
                    params={params}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idGetEndpoint={idGetEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    nameFiledReader={nameFiledReader}
                    relationParam={relationParam}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    token={token}
                    allowMultipleAttachment={allowMultipleAttachment}
                    idGetEnpointSwiper1={idGetEnpointSwiper1}
                    idGetEnpointSwiper2={idGetEnpointSwiper2}
                    idGetEnpointSwiper3={idGetEnpointSwiper3}
                    relationParamData={relationParamData}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    globalVars={globalVars}
                    localVars={localVars}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={rootReaderData}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                    responseAddUpdateDb={responseAddUpdateDb}
                    cartParamData={cartParamData}
                    carouselOriginState={carouselOriginState}
                  />
                ))
              }
            </div>
          );
        }
      }
      return null;
    }
    if (component.sluglofty === 'itemRelationCartData') {
      if (!rootReaderData || rootReaderData?.length <= 0) {
        return null;
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component?.components?.map((item) => (
              <DynamicComponent
                idProject={idProject}
                component={item}
                dataEndpoints={dataEndpoints}
                objectReader={rootReaderData?.relationdata}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'itemCartData') || slugLoftyEqualTo(component, 'itemCartField')) {
      if (!objectReader || objectReader?.length <= 0) {
        return null;
      }
      return (
        <>
          {
            objectReader?.map((data) => (
              <div className={manageClasses(component)} id={component.attributes?.id}>
                {
                  component?.components?.map((item) => {
                    if (item.sluglofty === 'quantityItemCartData') {
                      const textSeeker = item?.components?.find((index) => (index.type === 'textnode'));
                      return (
                        <div className={manageClasses(item)} id={item.attributes?.id}>
                          {`${textSeeker?.content || 'Qty: '} ${data.quantity}`}
                        </div>
                      );
                    }
                    return (
                      <DynamicComponent
                        idProject={idProject}
                        component={item}
                        dataEndpoints={dataEndpoints}
                        objectReader={data.cartitem}
                        params={params}
                        idDeleteEndpoint={idDeleteEndpoint}
                        idGetEndpoint={idGetEndpoint}
                        idPostEndpoint={idPostEndpoint}
                        idPutEndpoint={idPutEndpoint}
                        nameFiledReader={nameFiledReader}
                        relationParam={relationParam}
                        deletetokenApp={deletetokenApp}
                        dataGenerate={dataGenerate}
                        // plugin del calnedario
                        activeMonth={activeMonth}
                        changeLastMonth={changeLastMonth}
                        changeNextMonth={changeNextMonth}
                        listHours={listHours}
                        now={now}
                        selectDate={selectDate}
                        changeHour={changeHour}
                        submitCalendar={submitCalendar}
                        addTokenApp={addTokenApp}
                        addUserApp={addUserApp}
                        user={user}
                        useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                        token={token}
                        allowMultipleAttachment={allowMultipleAttachment}
                        idGetEnpointSwiper1={idGetEnpointSwiper1}
                        idGetEnpointSwiper2={idGetEnpointSwiper2}
                        idGetEnpointSwiper3={idGetEnpointSwiper3}
                        relationParamData={relationParamData}
                        userInfo={userInfo}
                        databaseSelectionForFields={databaseSelectionForFields}
                        // STATES
                        globalVariables={globalVariables}
                        setGlobalState={setGlobalState}
                        localState={localState}
                        setLocalState={setLocalState}
                        globalVars={globalVars}
                        localVars={localVars}
                        // Input With Variables
                        inputStateVariable={inputStateVariable}
                        // For Read Db with dbField data
                        rootReaderData={data}
                        refurbishEndpoint={refurbishEndpoint}
                        variableForEndpoint={variableForEndpoint}
                        responseAddUpdateDb={responseAddUpdateDb}
                        cartParamData={cartParamData}
                        carouselOriginState={carouselOriginState}
                      />
                    );
                  })
                }
              </div>
            ))
          }
        </>
      );
    }
    if (slugLoftyEqualTo(component, 'itemListArrayVariable') || slugLoftyEqualTo(component, 'itemArrayField')) {
      if (!objectReader || objectReader?.length <= 0) {
        return null;
      }
      return (
        <>
          {
            objectReader?.map((data) => (
              <div className={manageClasses(component)} id={component.attributes?.id}>
                {
                  component?.components?.map((item) => (
                    <DynamicComponent
                      idProject={idProject}
                      component={item}
                      dataEndpoints={dataEndpoints}
                      objectReader={data}
                      params={params}
                      idDeleteEndpoint={idDeleteEndpoint}
                      idGetEndpoint={idGetEndpoint}
                      idPostEndpoint={idPostEndpoint}
                      idPutEndpoint={idPutEndpoint}
                      nameFiledReader={nameFiledReader}
                      relationParam={relationParam}
                      deletetokenApp={deletetokenApp}
                      dataGenerate={dataGenerate}
                      // plugin del calnedario
                      activeMonth={activeMonth}
                      changeLastMonth={changeLastMonth}
                      changeNextMonth={changeNextMonth}
                      listHours={listHours}
                      now={now}
                      selectDate={selectDate}
                      changeHour={changeHour}
                      submitCalendar={submitCalendar}
                      addTokenApp={addTokenApp}
                      addUserApp={addUserApp}
                      user={user}
                      useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                      token={token}
                      allowMultipleAttachment={allowMultipleAttachment}
                      idGetEnpointSwiper1={idGetEnpointSwiper1}
                      idGetEnpointSwiper2={idGetEnpointSwiper2}
                      idGetEnpointSwiper3={idGetEnpointSwiper3}
                      relationParamData={relationParamData}
                      userInfo={userInfo}
                      databaseSelectionForFields={databaseSelectionForFields}
                      // STATES
                      globalVariables={globalVariables}
                      setGlobalState={setGlobalState}
                      localState={localState}
                      setLocalState={setLocalState}
                      globalVars={globalVars}
                      localVars={localVars}
                      // Input With Variables
                      inputStateVariable={inputStateVariable}
                      // For Read Db with dbField data
                      rootReaderData={data}
                      refurbishEndpoint={refurbishEndpoint}
                      variableForEndpoint={variableForEndpoint}
                      responseAddUpdateDb={responseAddUpdateDb}
                      cartParamData={cartParamData}
                      carouselOriginState={carouselOriginState}
                    />
                  ))
                }
              </div>
            ))
          }
        </>
      );
    }
    if (slugLoftyEqualTo(component, 'variableArrayComponent')) {
      if (component?.localVars) {
        return (
          <div className={manageClasses(component)} id={component.attributes?.id}>
            {findArrayLength(component?.localVars, localState)}
          </div>
        );
      }
      if (component?.globalVars) {
        return (
          <div className={manageClasses(component)} id={component.attributes?.id}>
            {findArrayLength(component?.globalVars, globalVariables)}
          </div>
        );
      }
      return null;
    }
    if (slugLoftyEqualTo(component, 'formInputContainer')) {
      const searchIfExists = findVariableExists(component?.variableForInput, localState, globalVariables);
      if (searchIfExists === 'isGlobal' || searchIfExists === 'isLocal') {
        const inputWithVariable = {
          variableId: component?.variableForInput,
          typeOfInput: component?.inputType,
          variableLocation: searchIfExists,
        };
        return (
          <div className={manageClasses(component)} id={component.attributes?.id}>
            {
              component?.components?.map((item) => (
                <DynamicComponent
                  component={item}
                  idProject={idProject}
                  objectReader={objectReader}
                  idGetEndpoint={idGetEndpoint}
                  dataEndpoints={dataEndpoints}
                  nameFiledReader={component.valuelofty}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputWithVariable}
                  // For Read Db with dbField data
                  rootReaderData={rootReaderData}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              ))
            }
          </div>
        );
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                idProject={idProject}
                objectReader={objectReader}
                idGetEndpoint={idGetEndpoint}
                dataEndpoints={dataEndpoints}
                nameFiledReader={component.valuelofty}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={[]}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    //
    if (slugLoftyEqualTo(component, 'booleanDynamicComponent')) {
      // console.log(component, objectReader, globalVariables);
      if (component?.variables) {
        const localStateBoolean = searchBooleanState(component?.variables, localState);
        const globalStateBoolean = searchBooleanState(component?.variables, globalVariables);
        if (localStateBoolean !== 'No Variable') {
          if (localStateBoolean) {
            const trueDiv = component.components?.find((index) => (index.sluglofty === 'booleanTrueDivDynamic'));
            if (!trueDiv) {
              return null;
            }
            return (
              <div className={manageClasses(component)} id={trueDiv?.attributes?.id} style={{display: localStateBoolean ? 'block' : 'none'}}>
                <DynamicComponent
                  idProject={idProject}
                  component={trueDiv}
                  dataEndpoints={dataEndpoints}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  objectReader={objectReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={rootReaderData}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              </div>
            );
          }
          const falseDiv = component.components?.find((index) => (index.sluglofty === 'booleanFalseDivDynamic'));
          if (!falseDiv) {
            return null;
          }
          return (
            <div className={manageClasses(component)} id={falseDiv?.attributes?.id} style={{display: !localStateBoolean ? 'block' : 'none'}}>
              <DynamicComponent
                idProject={idProject}
                component={falseDiv}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            </div>
          );
        } else if (globalStateBoolean !== 'No Variable') {
          if (globalStateBoolean) {
            const trueDiv = component.components?.find((index) => (index.sluglofty === 'booleanTrueDivDynamic'));
            if (!trueDiv) {
              return null;
            }
            return (
              <div className={manageClasses(component)} id={trueDiv?.attributes?.id} style={{display: globalStateBoolean ? 'block' : 'none'}}>
                <DynamicComponent
                  idProject={idProject}
                  component={trueDiv}
                  dataEndpoints={dataEndpoints}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  objectReader={objectReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={rootReaderData}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              </div>
            );
          }
          const falseDiv = component.components?.find((index) => (index.sluglofty === 'booleanFalseDivDynamic'));
          if (!falseDiv) {
            return null;
          }
          return (
            <div className={manageClasses(component)} id={falseDiv?.attributes?.id} style={{display: !globalStateBoolean ? 'block' : 'none'}}>
              <DynamicComponent
                idProject={idProject}
                component={falseDiv}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            </div>
          );
        }
      } else if (component?.fields) {
        // console.log(component);
        // console.log(objectReader);
        const valueCheck = component.fields;
        // console.log(objectReader && (valueCheck in objectReader));
        let flagOfValue = false;
        if ((objectReader && (valueCheck in objectReader)) || (userInfo && userInfo[valueCheck])) {
          flagOfValue = true;
          const trueDiv = component.components?.find((index) => (index.sluglofty === 'booleanTrueDivDynamic'));
          if (!trueDiv) {
            // console.log('Dead end');
            return null;
          }
          return (
            <div className={manageClasses(component)} id={trueDiv?.attributes?.id} style={{display: flagOfValue ? 'block' : 'none'}}>
              <DynamicComponent
                idProject={idProject}
                component={trueDiv}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            </div>
          );
        }
        const falseDiv = component.components?.find((index) => (index.sluglofty === 'booleanFalseDivDynamic'));
        if (!falseDiv) {
          return null;
        }
        return (
          <div className={manageClasses(component)} id={falseDiv?.attributes?.id} style={{display: !flagOfValue ? 'block' : 'none'}}>
            <DynamicComponent
              idProject={idProject}
              component={falseDiv}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          </div>
        );
      } else if (component?.booleanVerifyType === 'userauth') {
        let flagOfUser = false;
        if (userInfo || user) {
          flagOfUser = true;
          const trueDiv = component.components?.find((index) => (index.sluglofty === 'booleanTrueDivDynamic'));
          if (!trueDiv) {
            // console.log('Dead end');
            return null;
          }
          return (
            <div className={manageClasses(component)} id={trueDiv?.attributes?.id} style={{display: flagOfUser ? 'block' : 'none'}}>
              <DynamicComponent
                idProject={idProject}
                component={trueDiv}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            </div>
          );
        }
        const falseDiv = component.components?.find((index) => (index.sluglofty === 'booleanFalseDivDynamic'));
        if (!falseDiv) {
          return null;
        }
        return (
          <div className={manageClasses(component)} id={falseDiv?.attributes?.id} style={{display: !flagOfUser ? 'block' : 'none'}}>
            <DynamicComponent
              idProject={idProject}
              component={falseDiv}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          </div>
        );
      }
      return null;
    }
    if (slugLoftyEqualTo(component, 'booleanTrueDivDynamic') || slugLoftyEqualTo(component, 'booleanFalseDivDynamic')) {
      return (
        <div className={manageClasses(component)} id={component.attributes?.id} style={{display: 'block'}}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                idProject={idProject}
                objectReader={objectReader}
                idGetEndpoint={idGetEndpoint}
                dataEndpoints={dataEndpoints}
                nameFiledReader={component.valuelofty}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'variableComponent')) {
      if (component?.components?.find((index) => (
        index.sluglofty === 'listArrayVariable' || index.sluglofty === 'oneDataVariable' || index.sluglofty === 'listCartData'
      ))) {
        let dataState = [];
        if (component?.localVars) {
          dataState = searchMyValue(component?.localVars, localState);
          const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
              component.components?.map((item) => (
                <DynamicComponent
                  component={item}
                  idProject={idProject}
                  dataEndpoints={dataEndpoints}
                  idGetEndpoint={component.endpointSelect}
                  params={params}
                  objectReader={dataState}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  loftyUrlData={loftyUrlInfo}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={component?.localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={dataState}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              ))
              }
            </div>
          );
        }
        if (component?.globalVars) {
          dataState = searchMyValue(component?.globalVars, globalVariables);
          const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
              component.components?.map((item) => (
                <DynamicComponent
                  component={item}
                  idProject={idProject}
                  dataEndpoints={dataEndpoints}
                  idGetEndpoint={component.endpointSelect}
                  params={params}
                  objectReader={dataState}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  loftyUrlData={loftyUrlInfo}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={component?.globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={dataState}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              ))
              }
            </div>
          );
        }
        return null;
      }
      if (component?.localVars) {
        let returnVal = searchMyValue(component?.localVars, localState);
        if (component?.numberFormatVar === 'decimal') {
          returnVal = new Intl.NumberFormat('en-us', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(returnVal);
        }
        return (
          <div className={manageClasses(component)} id={component.attributes?.id}>
            {returnVal}
          </div>
        );
      }
      let returnVal = searchMyValue(component?.globalVars, globalVariables);
      if (component?.numberFormatVar === 'decimal') {
        returnVal = new Intl.NumberFormat('en-us', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(returnVal);
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {returnVal}
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'modalComponent')) {
      return (
        <div className="modal fade" id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idPostEndpoint={component.endpointSelect}
                idProject={idProject}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={component.useCurrentUserAddDatabase}
                allowMultipleAttachment={component.allowMultipleAttachment}
                token={token}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={component?.attributes?.relationInputs}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                globalVars={globalVars}
                localVars={localVars}
                localState={localState}
                setLocalState={setLocalState}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (component.sluglofty === 'userDefaultFieldDatabaseContainer' || component.sluglofty === 'userFieldDatabaseContainer') {
      let value = '';
      if (userInfo && userInfo[component.valuelofty]) {
        value = userInfo[component.valuelofty];
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {value}
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'hamburgerContainerAnimated')) {
      setTimeout(() => {
        initHam();
      }, 2000);
    }

    if (slugLoftyEqualTo(component, 'modalInitPage')) {
      setTimeout(() => {
        initModal(component);
      }, 2000);

      return (
        <div className="modal" id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idPostEndpoint={component.endpointSelect}
                idProject={idProject}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }

    if (slugLoftyEqualTo(component, 'modalInitContainer')) {
      return (
        <div className={`${manageClasses(component)} modal-content`} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idPostEndpoint={component.endpointSelect}
                idProject={idProject}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                deletetokenApp={deletetokenApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }

    if (slugLoftyEqualTo(component, 'mainModalContainer')) {
      // console.log(component);
      return (
        component.components?.map((data) => (
          <DynamicComponent
            component={data}
            dataEndpoints={dataEndpoints}
            idPostEndpoint={component.endpointSelect}
            idProject={idProject}
            params={params}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={idGetEndpoint}
            idPutEndpoint={idPutEndpoint}
            nameFiledReader={nameFiledReader}
            objectReader={objectReader}
            relationParam={relationParam}
            dataGenerate={dataGenerate}
            // plugin del calnedario
            activeMonth={activeMonth}
            changeLastMonth={changeLastMonth}
            changeNextMonth={changeNextMonth}
            listHours={listHours}
            now={now}
            selectDate={selectDate}
            changeHour={changeHour}
            submitCalendar={submitCalendar}
            idModalHandler={component.attributes?.id}
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            deletetokenApp={deletetokenApp}
            user={user}
            useCurrentUserAddDatabase={useCurrentUserAddDatabase}
            token={token}
            allowMultipleAttachment={allowMultipleAttachment}
            idGetEnpointSwiper1={idGetEnpointSwiper1}
            idGetEnpointSwiper2={idGetEnpointSwiper2}
            idGetEnpointSwiper3={idGetEnpointSwiper3}
            relationParamData={relationParamData}
            userInfo={userInfo}
            databaseSelectionForFields={databaseSelectionForFields}
            // STATES
            globalVariables={globalVariables}
            setGlobalState={setGlobalState}
            localState={localState}
            setLocalState={setLocalState}
            globalVars={globalVars}
            localVars={localVars}
            // Input With Variables
            inputStateVariable={inputStateVariable}
            // For Read Db with dbField data
            rootReaderData={rootReaderData}
            refurbishEndpoint={refurbishEndpoint}
            variableForEndpoint={variableForEndpoint}
            responseAddUpdateDb={responseAddUpdateDb}
            cartParamData={cartParamData}
            carouselOriginState={carouselOriginState}
          />
        )));
    }

    /* Seccion div del plugin del calendario */
    if (slugLoftyEqualTo(component, 'calendarComponent')) {
      return (
        <CalendarDynamic
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          component={component}
          dataEndpoints={dataEndpoints}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idProject={idProject}
          idPutEndpoint={idPutEndpoint}
          nameFiledReader={nameFiledReader}
          objectReader={objectReader}
          params={params}
          relationParam={relationParam}
          token={token}
          deletetokenApp={deletetokenApp}
          dataGenerate={dataGenerate}
          user={user}
          userInfo={userInfo}
          databaseSelectionForFields={databaseSelectionForFields}
          // STATES
          globalVariables={globalVariables}
          setGlobalState={setGlobalState}
          localState={localState}
          setLocalState={setLocalState}
          globalVars={globalVars}
          localVars={localVars}
          // Input With Variables
          inputStateVariable={inputStateVariable}
          // For Read Db with dbField data
          rootReaderData={rootReaderData}
          refurbishEndpoint={refurbishEndpoint}
          variableForEndpoint={variableForEndpoint}
          responseAddUpdateDb={responseAddUpdateDb}
          cartParamData={cartParamData}
          carouselOriginState={carouselOriginState}
        />
      );
    }
    if (
      slugLoftyEqualTo(component, 'calendarTitleName') || slugLoftyEqualTo(component, 'AMButtonSection')
      || slugLoftyEqualTo(component, 'PMButtonSection')
    ) {
      return (
        <ItemCalendar
          component={component}
          activeMonth={activeMonth}
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          dataEndpoints={dataEndpoints}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idProject={idProject}
          idPutEndpoint={idPutEndpoint}
          nameFiledReader={nameFiledReader}
          objectReader={objectReader}
          params={params}
          relationParam={relationParam}
          token={token}
          changeLastMonth={changeLastMonth}
          changeNextMonth={changeNextMonth}
          now={now}
          selectDate={selectDate}
          listHours={listHours}
          changeHour={changeHour}
          submitCalendar={submitCalendar}
          deletetokenApp={deletetokenApp}
          user={user}
          userInfo={userInfo}
          databaseSelectionForFields={databaseSelectionForFields}
          // STATES
          globalVariables={globalVariables}
          setGlobalState={setGlobalState}
          localState={localState}
          setLocalState={setLocalState}
          globalVars={globalVars}
          localVars={localVars}
          // Input With Variables
          inputStateVariable={inputStateVariable}
          // For Read Db with dbField data
          rootReaderData={rootReaderData}
          refurbishEndpoint={refurbishEndpoint}
          variableForEndpoint={variableForEndpoint}
          responseAddUpdateDb={responseAddUpdateDb}
          cartParamData={cartParamData}
          carouselOriginState={carouselOriginState}
        />
      );
    }
    /** Fin de la sección del plugin del calendario */
    if (slugLoftyEqualTo(component, 'AddDBContainer')) {
      let resData = {};
      if (component?.alertTrait === 'alert' && component?.alertMessage) {
        resData = {type: 'alert', content: component?.alertMessage};
      }
      if (component?.alertTrait === 'function' && component?.customFunctionPlugin) {
        const logicData = component?.attributes?.customPageFunction?.arrayLogic[0]?.blocks?.find((index) => (index.type === 'main_block'));
        if (logicData?.type === 'main_block') {
          resData = {type: 'function', content: logicData};
        }
      }
      if (component?.alertTrait === 'redirect' && component?.pageRedirect) {
        resData = {type: 'redirect', content: component?.pageRedirect, configs: component?.attributes?.redirectWithEndpoint};
      }
      let hasCartData = {};
      if (component?.attributes?.cartParams) {
        hasCartData = component?.attributes?.cartParams;
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idPostEndpoint={component.endpointSelect}
                idProject={idProject}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={component.useCurrentUserAddDatabase}
                allowMultipleAttachment={component.allowMultipleAttachment}
                token={token}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={component?.attributes?.relationInputs}
                userInfo={userInfo}
                databaseSelectionForFields={component?.dbSelect}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={resData}
                cartParamData={hasCartData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'UpdateDBContainer')) {
      let resData = {};
      if (component?.alertTrait === 'alert' && component?.alertMessage) {
        resData = {type: 'alert', content: component?.alertMessage};
      }
      if (component?.alertTrait === 'function' && component?.customFunctionPlugin) {
        const logicData = component?.attributes?.customPageFunction?.arrayLogic[0]?.blocks?.find((index) => (index.type === 'main_block'));
        if (logicData?.type === 'main_block') {
          resData = {type: 'function', content: logicData};
        }
      }
      let filterData = '';
      if (!component?.filterUpdate && !component?.attributes?.customParams) {
        return null;
      }
      if (component.typeOfFilterManager === 'readcomponent' && component?.filterUpdate) {
        const valueOfFilter = component.filterUpdate;
        if (rootReaderData === undefined) {
          return null;
        }
        if (rootReaderData && !(valueOfFilter in rootReaderData)) {
          return null;
        }
        filterData = rootReaderData[valueOfFilter];
      }
      if (component.typeOfFilterManager === 'variable' && component?.filterUpdate) {
        const valueOfFilter = component.filterUpdate;
        const possibleLocal = searchMyValue(valueOfFilter, localState);
        const possibleGlobal = searchMyValue(valueOfFilter, globalVariables);
        if (possibleLocal !== '') {
          filterData = possibleLocal;
        } else if (possibleGlobal !== '') {
          filterData = possibleGlobal;
        } else {
          return null;
        }
      }
      if ((component.typeOfFilterManager === 'params' || !component.typeOfFilterManager) && component?.filterUpdate) {
        filterData = params[component.filterUpdate];
      }
      if (component?.attributes?.customParams) {
        filterData = component?.attributes?.customParams;
      }
      if (component?.alertTrait === 'redirect' && component?.pageRedirect) {
        resData = {type: 'redirect', content: component?.pageRedirect, configs: component?.attributes?.redirectWithEndpoint};
      }
      let hasCartData = {};
      if (component?.attributes?.cartParams) {
        hasCartData = component.attributes.cartParams;
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idPutEndpoint={component.endpointSelect}
                idProject={idProject}
                params={params}
                relationParam={filterData}
                idDeleteEndpoint={idDeleteEndpoint}
                idPostEndpoint={idPostEndpoint}
                idGetEndpoint={idGetEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={component.useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={component?.attributes?.relationInputs}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={resData}
                cartParamData={hasCartData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'DeleteDBContainer')) {
      let filterData = [];
      if (!component?.filterDelete) {
        return null;
      }
      if (component.typeOfFilterManager === 'readcomponent' && component?.filterDelete) {
        const valueOfFilter = component.filterDelete;
        if (rootReaderData === undefined) {
          return null;
        }
        if (rootReaderData && !(valueOfFilter in rootReaderData)) {
          return null;
        }
        filterData = rootReaderData[valueOfFilter];
      }
      if (component.typeOfFilterManager === 'variable' && component?.filterDelete) {
        const valueOfFilter = component.filterDelete;
        const possibleLocal = searchMyValue(valueOfFilter, localState);
        const possibleGlobal = searchMyValue(valueOfFilter, globalVariables);
        if (possibleLocal !== '') {
          filterData = possibleLocal;
        } else if (possibleGlobal !== '') {
          filterData = possibleGlobal;
        } else {
          return null;
        }
      }
      if ((component.typeOfFilterManager === 'params' || !component.typeOfFilterManager) && component?.filterUpdate) {
        filterData = params[component.filterDelete];
      }
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idDeleteEndpoint={component.endpointSelect}
                idProject={idProject}
                params={params}
                relationParam={filterData}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'ReadDBContainer')) {
      /**
       * =======================================
       * --------- FOR CUSTOM ENDPOINTS --------
       * =======================================
       *
       * @argument *Must have customParams*
       * Otherwise it is interpreted as normal read
       */
      if (component?.attributes?.customParams) {
        const findAllDBParams = component?.attributes?.customParams?.filter((index) => (index.state === 'dbfield'));
        if (findAllDBParams.length > 0) {
          if (rootReaderData === undefined) {
            return null;
          }
          const objectInfo = dataEndpoints?.find((item) => (
            item.id === component.endpointSelect
            && (item.fromOriginId === rootReaderData._id || item.fromOriginListId === rootReaderData._id)
          ));
          if (!objectInfo) {
            return null;
          }
          const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
          const objectInfoData = objectInfo?.data?.data?.data || objectInfo?.data?.data;
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
              component.components?.map((item) => (
                <DynamicComponent
                  component={item}
                  idProject={idProject}
                  dataEndpoints={dataEndpoints}
                  idGetEndpoint={component.endpointSelect}
                  params={params}
                  objectReader={objectInfoData}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  loftyUrlData={loftyUrlInfo}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={objectInfoData}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              ))
              }
            </div>
          );
        }
        const objectInfo = dataEndpoints?.find((item) => item.id === component.endpointSelect);
        const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
        const objectInfoData = objectInfo?.data?.data?.data || objectInfo?.data?.data;
        return (
          <div className={manageClasses(component)} id={component.attributes?.id}>
            {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                idProject={idProject}
                dataEndpoints={dataEndpoints}
                idGetEndpoint={component.endpointSelect}
                params={params}
                objectReader={objectInfoData}
                idDeleteEndpoint={idDeleteEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                loftyUrlData={loftyUrlInfo}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={objectInfoData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
            }
          </div>
        );
      }
      if (component?.typeOfFilterManager === 'readcomponent' && component?.filterAdd) {
        //
        const valueOfFilter = component.filterAdd;
        if (rootReaderData === undefined) {
          return null;
        }
        if (rootReaderData && !(valueOfFilter in rootReaderData)) {
          return null;
        }
        const objectInfo = dataEndpoints?.find((item) => item.dataId === rootReaderData[valueOfFilter]);
        const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
        return (
          <div className={manageClasses(component)} id={component.attributes?.id}>
            {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                idProject={idProject}
                dataEndpoints={dataEndpoints}
                idGetEndpoint={component.endpointSelect}
                params={params}
                objectReader={objectInfo?.data?.data}
                idDeleteEndpoint={idDeleteEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                loftyUrlData={loftyUrlInfo}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={objectInfo?.data?.data}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
            }
          </div>
        );
      }
      const objectInfo = dataEndpoints?.find((item) => item.id === component.endpointSelect);
      const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
      // console.log(objectInfo, loftyUrlInfo);
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                idProject={idProject}
                dataEndpoints={dataEndpoints}
                idGetEndpoint={component.endpointSelect}
                params={params}
                objectReader={objectInfo?.data?.data}
                idDeleteEndpoint={idDeleteEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                loftyUrlData={loftyUrlInfo}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={objectInfo?.data?.data}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }
    if (slugLoftyEqualTo(component, 'itemListEndpointDatabase')) {
      // Exclusive for three carrousel only
      const seekIfComponentHasFromCarousel = dataEndpoints?.filter((item) => (item.id === idGetEndpoint && item.type === 'carouselOriginEndpoint'));
      if (seekIfComponentHasFromCarousel?.length > 0 && carouselOriginState === 'show') {
        return (
          objectReader?.map((data) => (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
                component.components?.map((item) => (
                  <DynamicComponent
                    idProject={idProject}
                    component={item}
                    dataEndpoints={dataEndpoints}
                    objectReader={data}
                    params={params}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idGetEndpoint={idGetEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    nameFiledReader={nameFiledReader}
                    relationParam={relationParam}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    token={token}
                    allowMultipleAttachment={allowMultipleAttachment}
                    idGetEnpointSwiper1={idGetEnpointSwiper1}
                    idGetEnpointSwiper2={idGetEnpointSwiper2}
                    idGetEnpointSwiper3={idGetEnpointSwiper3}
                    relationParamData={relationParamData}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    globalVars={globalVars}
                    localVars={localVars}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={data}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                    responseAddUpdateDb={responseAddUpdateDb}
                    cartParamData={cartParamData}
                    carouselOriginState={carouselOriginState}
                  />
                ))
              }
            </div>
          ))
        );
      }
      const endpointList = dataEndpoints?.filter((item) => (item.id === idGetEndpoint && item?.fromOriginListId));
      if (endpointList?.length > 0) {
        // May Cause bugs
        const endpointData = endpointList?.find((item) => (item.fromOriginListId === rootReaderData[0]._id));
        if (!endpointData) {
          return null;
        }
        return (
          <>
            {
              endpointData?.data?.data?.map((data) => (
                <div className={manageClasses(component)} id={component.attributes?.id}>
                  {
                    component.components?.map((item) => (
                      <DynamicComponent
                        idProject={idProject}
                        component={item}
                        dataEndpoints={dataEndpoints}
                        objectReader={data}
                        params={params}
                        idDeleteEndpoint={idDeleteEndpoint}
                        idGetEndpoint={idGetEndpoint}
                        idPostEndpoint={idPostEndpoint}
                        idPutEndpoint={idPutEndpoint}
                        nameFiledReader={nameFiledReader}
                        relationParam={relationParam}
                        deletetokenApp={deletetokenApp}
                        dataGenerate={dataGenerate}
                        // plugin del calnedario
                        activeMonth={activeMonth}
                        changeLastMonth={changeLastMonth}
                        changeNextMonth={changeNextMonth}
                        listHours={listHours}
                        now={now}
                        selectDate={selectDate}
                        changeHour={changeHour}
                        submitCalendar={submitCalendar}
                        addTokenApp={addTokenApp}
                        addUserApp={addUserApp}
                        user={user}
                        useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                        token={token}
                        allowMultipleAttachment={allowMultipleAttachment}
                        idGetEnpointSwiper1={idGetEnpointSwiper1}
                        idGetEnpointSwiper2={idGetEnpointSwiper2}
                        idGetEnpointSwiper3={idGetEnpointSwiper3}
                        relationParamData={relationParamData}
                        userInfo={userInfo}
                        databaseSelectionForFields={databaseSelectionForFields}
                        // STATES
                        globalVariables={globalVariables}
                        setGlobalState={setGlobalState}
                        localState={localState}
                        setLocalState={setLocalState}
                        globalVars={globalVars}
                        localVars={localVars}
                        // Input With Variables
                        inputStateVariable={inputStateVariable}
                        // For Read Db with dbField data
                        rootReaderData={data}
                        refurbishEndpoint={refurbishEndpoint}
                        variableForEndpoint={variableForEndpoint}
                        responseAddUpdateDb={responseAddUpdateDb}
                        cartParamData={cartParamData}
                        carouselOriginState={carouselOriginState}
                      />
                    ))
                  }
                </div>
              ))
            }
          </>
        );
      }
      const endpoint = dataEndpoints?.find((item) => (item.id === idGetEndpoint));
      if (endpoint && endpoint.endpoint.typeFunction === 'customList') {
        if (!endpoint?.data) {
          return null;
        }
        if (endpoint?.data?.data === '' || !endpoint?.data?.data) {
          return null;
        }
        return (
          <>
            {
              endpoint?.data?.data?.map((data) => (
                <div className={manageClasses(component)} id={component.attributes?.id}>
                  {
                    component.components?.map((item) => (
                      <DynamicComponent
                        idProject={idProject}
                        component={item}
                        dataEndpoints={dataEndpoints}
                        objectReader={data}
                        params={params}
                        idDeleteEndpoint={idDeleteEndpoint}
                        idGetEndpoint={idGetEndpoint}
                        idPostEndpoint={idPostEndpoint}
                        idPutEndpoint={idPutEndpoint}
                        nameFiledReader={nameFiledReader}
                        relationParam={relationParam}
                        deletetokenApp={deletetokenApp}
                        dataGenerate={dataGenerate}
                        // plugin del calnedario
                        activeMonth={activeMonth}
                        changeLastMonth={changeLastMonth}
                        changeNextMonth={changeNextMonth}
                        listHours={listHours}
                        now={now}
                        selectDate={selectDate}
                        changeHour={changeHour}
                        submitCalendar={submitCalendar}
                        addTokenApp={addTokenApp}
                        addUserApp={addUserApp}
                        user={user}
                        useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                        token={token}
                        allowMultipleAttachment={allowMultipleAttachment}
                        idGetEnpointSwiper1={idGetEnpointSwiper1}
                        idGetEnpointSwiper2={idGetEnpointSwiper2}
                        idGetEnpointSwiper3={idGetEnpointSwiper3}
                        relationParamData={relationParamData}
                        userInfo={userInfo}
                        databaseSelectionForFields={databaseSelectionForFields}
                        // STATES
                        globalVariables={globalVariables}
                        setGlobalState={setGlobalState}
                        localState={localState}
                        setLocalState={setLocalState}
                        globalVars={globalVars}
                        localVars={localVars}
                        // Input With Variables
                        inputStateVariable={inputStateVariable}
                        // For Read Db with dbField data
                        rootReaderData={data}
                        refurbishEndpoint={refurbishEndpoint}
                        variableForEndpoint={variableForEndpoint}
                        responseAddUpdateDb={responseAddUpdateDb}
                        cartParamData={cartParamData}
                        carouselOriginState={carouselOriginState}
                      />
                    ))
                  }
                </div>
              ))
            }
          </>
        );
      } else if (endpoint && endpoint.endpoint.typeFunction === 'list') {
        return (
          <>
            {
              endpoint.data.data?.map((data) => (
                <div className={manageClasses(component)} id={component.attributes?.id}>
                  {
                    component.components?.map((item) => (
                      <DynamicComponent
                        idProject={idProject}
                        component={item}
                        dataEndpoints={dataEndpoints}
                        objectReader={data}
                        params={params}
                        idDeleteEndpoint={idDeleteEndpoint}
                        idGetEndpoint={idGetEndpoint}
                        idPostEndpoint={idPostEndpoint}
                        idPutEndpoint={idPutEndpoint}
                        nameFiledReader={nameFiledReader}
                        relationParam={relationParam}
                        deletetokenApp={deletetokenApp}
                        dataGenerate={dataGenerate}
                        // plugin del calnedario
                        activeMonth={activeMonth}
                        changeLastMonth={changeLastMonth}
                        changeNextMonth={changeNextMonth}
                        listHours={listHours}
                        now={now}
                        selectDate={selectDate}
                        changeHour={changeHour}
                        submitCalendar={submitCalendar}
                        addTokenApp={addTokenApp}
                        addUserApp={addUserApp}
                        user={user}
                        useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                        token={token}
                        allowMultipleAttachment={allowMultipleAttachment}
                        idGetEnpointSwiper1={idGetEnpointSwiper1}
                        idGetEnpointSwiper2={idGetEnpointSwiper2}
                        idGetEnpointSwiper3={idGetEnpointSwiper3}
                        relationParamData={relationParamData}
                        userInfo={userInfo}
                        databaseSelectionForFields={databaseSelectionForFields}
                        // STATES
                        globalVariables={globalVariables}
                        setGlobalState={setGlobalState}
                        localState={localState}
                        setLocalState={setLocalState}
                        globalVars={globalVars}
                        localVars={localVars}
                        // Input With Variables
                        inputStateVariable={inputStateVariable}
                        // For Read Db with dbField data
                        rootReaderData={data}
                        refurbishEndpoint={refurbishEndpoint}
                        variableForEndpoint={variableForEndpoint}
                        responseAddUpdateDb={responseAddUpdateDb}
                        cartParamData={cartParamData}
                        carouselOriginState={carouselOriginState}
                      />
                    ))
                  }
                </div>
              ))
            }
          </>
        );
      }
    }
    if (slugLoftyEqualTo(component, 'fieldArrayDatabaseFieldDatabase')
      || (slugLoftyEqualTo(component, 'fieldCartFieldDatabase'))
    ) {
      try {
        if (carouselOriginState && carouselOriginState === 'hide') {
          return null;
        }
        const valueCheck = component.valuelofty;
        if (objectReader === undefined) {
          return null;
        }
        if (objectReader && !(valueCheck in objectReader)) {
          return null;
        }
        if (objectReader[valueCheck]) {
          const loftyUrlInfo = component?.components?.find((item) => item.valuelofty === 'loftyUrl');
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
              component.components?.map((item) => (
                <DynamicComponent
                  component={item}
                  idProject={idProject}
                  dataEndpoints={dataEndpoints}
                  idGetEndpoint={component.endpointSelect}
                  params={params}
                  objectReader={objectReader[valueCheck]}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  loftyUrlData={loftyUrlInfo}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={component?.localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={objectReader[valueCheck]}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              ))
              }
            </div>
          );
        }
      } catch (error) {
        return null;
      }
    }
    if (
      slugLoftyEqualTo(component, 'fieldDatabaseContainer')
      || slugLoftyEqualTo(component, 'fieldBucketDatabaseContainer')
    ) {
      const valueCheck = component.valuelofty;
      try {
        if (carouselOriginState && carouselOriginState === 'hide') {
          return null;
        }
        if (objectReader === undefined) {
          return null;
        }
        if (objectReader && !(valueCheck in objectReader)) {
          return null;
        }
        // return null;
        if (objectReader && validDesignation(valueCheck, objectReader)) {
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
              component.components?.map((item) => (
                <DynamicComponent
                  component={item}
                  idProject={idProject}
                  objectReader={objectReader}
                  idGetEndpoint={idGetEndpoint}
                  dataEndpoints={dataEndpoints}
                  nameFiledReader={component.valuelofty}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  token={token}
                  allowMultipleAttachment={allowMultipleAttachment}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={rootReaderData}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              ))
            }
            </div>
          );
        }
        return null;
      } catch (error) {
        return null;
      }
    }
    if (slugLoftyEqualTo(component, 'fieldDatabaseTextRich')) {
      if (objectReader) {
        return (
          <EditorJsReadOnly
            id={component.attributes?.id}
            className={manageClasses(component)}
            defaultValue={objectReader && JSON.parse(objectReader[nameFiledReader])}
          />
        );
      } else {
        return <div id={component.attributes?.id} className={manageClasses(component)} />;
      }
    }
    if (
      slugLoftyEqualTo(component, 'fieldDatabaseText')
      || slugLoftyEqualTo(component, 'fieldDatabaseloftyOriginalName')
      || slugLoftyEqualTo(component, 'fieldDatabaseloftyFileName')
      || slugLoftyEqualTo(component, 'fieldDatabaseloftyUrl')
    ) {
      try {
        if (carouselOriginState && carouselOriginState === 'hide') {
          return null;
        }
        if (objectReader[nameFiledReader]) {
          return (
            <div className={manageClasses(component)} id={component.attributes?.id}>
              {
              (objectReader && objectReader[nameFiledReader]) ? typeOfObjectReader(objectReader[nameFiledReader], nameFiledReader, idGetEndpoint, dataGenerate, localVars, globalVars) : (
                component.components?.map((item) => (
                  <DynamicComponent
                    component={item}
                    idProject={idProject}
                    dataEndpoints={dataEndpoints}
                    idGetEndpoint={idGetEndpoint}
                    params={params}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    nameFiledReader={nameFiledReader}
                    objectReader={objectReader}
                    relationParam={relationParam}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    token={token}
                    allowMultipleAttachment={allowMultipleAttachment}
                    idGetEnpointSwiper1={idGetEnpointSwiper1}
                    idGetEnpointSwiper2={idGetEnpointSwiper2}
                    idGetEnpointSwiper3={idGetEnpointSwiper3}
                    relationParamData={relationParamData}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    globalVars={globalVars}
                    localVars={localVars}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={rootReaderData}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                    responseAddUpdateDb={responseAddUpdateDb}
                    cartParamData={cartParamData}
                    carouselOriginState={carouselOriginState}
                  />
                ))
              )
            }
            </div>
          );
        }
        return null;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
    if (slugLoftyEqualTo(component, 'verifyUserEmail')) {
      // await verifySimulatorVerify({idProject, token, tokenVerify: token});
      const paramFilter = component?.verifyParams;
      useEffect(() => {
        handleVerifyEmailSimulator(idProject, token, params[paramFilter]);
      }, []);
      return (
        <div className={manageClasses(component)} id={componentInput.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                idProject={idProject}
                component={item}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }

    if (slugLoftyEqualTo(component, 'ThreeSwiperComponent')) {
      return null;
    }

    if (slugLoftyEqualTo(component, 'ThreeSwiperComponentSub1')) {
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                idProject={idProject}
                component={item}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEnpointSwiper1}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }

    if (slugLoftyEqualTo(component, 'ThreeSwiperComponentSub2')) {
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                idProject={idProject}
                component={item}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEnpointSwiper2}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }

    if (slugLoftyEqualTo(component, 'ThreeSwiperComponentSub3')) {
      return (
        <div className={manageClasses(component)} id={component.attributes?.id}>
          {
            component.components?.map((item) => (
              <DynamicComponent
                idProject={idProject}
                component={item}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEnpointSwiper3}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                token={token}
                allowMultipleAttachment={allowMultipleAttachment}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </div>
      );
    }

    return (
      <div className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </div>
    );
  }

  // USER AUTH
  if (componentUserAuth({component})) {
    const multipleAttachments = {action: component?.actionRegister || component?.actionLogout, redirect: component?.redirectPage, alertMessage: component?.alertMessage};
    // console.log(multipleAttachments);

    return (
      <div className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              userAuthVariables={multipleAttachments}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </div>
    );
  }
  // formulario
  if (componentForm({component})) {
    if (slugLoftyEqualTo(component, 'formUpdateUserInfo')) {
      allowMultipleAttachment = 'handleUpdateUserInfo';
    }
    // const valueHandleUpdate = 'handleUpdateUserInfo';
    return (
      <form
        id={component.attributes?.id}
        className={manageClasses(component)}
        action={userAuthVariables?.redirect}
        onSubmit={async (e) => {
          if (slugLoftyEqualTo(component, 'formAddDatabase')) {
            /**
             * WILL REQUIRE THE FIELDS FOR INPUT TYPE COMPARISON
             * USE THE VALUE [databaseSelectionForFields] TO GET FIELDS
             */
            e.preventDefault();
            if (idPostEndpoint) {
              const inputString = searchComponentsBySlug(component.components, 'formInputDatabase');
              const inputDate = searchComponentsBySlug(component.components, 'formDateInputDatabase');
              const inputNumber = searchComponentsBySlug(component.components, 'formNumberInputDatabase');
              const inputBoolean = searchComponentsBySlug(component.components, 'formBooleanInputDatabase');
              const selectRelation = searchComponentsBySlug(component.components, 'formSelectRelation');
              const selectUser = searchComponentsBySlug(component.components, 'formSelectUser');
              const inputTextrich = searchComponentsBySlug(component.components, 'formRichTextDatabase');
              const inputSelectStringNormal = searchComponentsBySlug(component.components, 'formSelectAdd');
              const inputTextarea = searchComponentsBySlug(component.components, 'formTextarea');
              const body = {};
              const fields = [];
              inputString.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              inputTextarea.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              inputSelectStringNormal.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              inputDate.forEach((field) => {
                if (field.attributes?.name) {
                  const dateForBody = new Date(e.target[field.attributes?.name].value);
                  body[field.attributes?.name] = dateForBody;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              inputNumber.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              inputBoolean.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              selectRelation.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              selectUser.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes?.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              inputTextrich.forEach((field) => {
                if (field.attributes?.name) {
                  body[field.attributes.name] = e.target[field.attributes?.name].value;
                  fields.push({
                    name: field.attributes?.name,
                    value: e.target[field.attributes?.name].value,
                  });
                }
              });
              if (relationParamData?.length > 0) {
                relationParamData.forEach((field) => {
                  if (field.state === 'useparams') {
                    const resParam = params[field.param];
                    body[field.relationInput.name] = resParam;
                    fields.push({
                      name: field.relationInput.name,
                      value: resParam,
                    });
                  }
                });
              }
              // SEARCH FOR CART DATA
              if (cartParamData?.length > 0) {
                const arrayOfParams = cartParamData;
                const findAllDBParams = arrayOfParams?.filter((index) => (index.state === 'dbfield'));
                const findAllVariableParams = arrayOfParams?.filter((index) => (index.state === 'variable'));
                if (findAllVariableParams?.length > 0) {
                  findAllVariableParams.forEach((index) => {
                    const findVariable = findVariableExists(index.param, localState, globalVariables);
                    if (findVariable === 'isGlobal') {
                      const resSearch = searchMyValue(index.param, globalVariables);
                      body[index.paramendpoint.value] = resSearch;
                      fields.push({
                        name: index.paramendpoint.value,
                        value: resSearch,
                      });
                    } else if (findVariable === 'isLocal') {
                      const resSearch = searchMyValue(index.param, localState);
                      body[index.paramendpoint.value] = resSearch;
                      fields.push({
                        name: index.paramendpoint.value,
                        value: resSearch,
                      });
                    }
                  });
                }
                if (findAllDBParams?.length > 0) {
                  findAllDBParams.forEach((index) => {
                    if (rootReaderData[index.param]) {
                      body[index.paramendpoint.value] = rootReaderData[index.param];
                      fields.push({
                        name: index.paramendpoint.value,
                        value: rootReaderData[index.param],
                      });
                    }
                  });
                }
              }
              try {
                let resFromQuery = [];
                const endpoint = dataGenerate?.endpoints?.find((item) => item._id === idPostEndpoint);
                const database = dataGenerate?.databases?.find((item) => item._id === endpoint.idDatabase);
                // agregando cuando es el usuario logueado
                if (useCurrentUserAddDatabase === 'usecurrent') {
                  const fieldsUser = dataGenerate.fieldsDatabase?.filter((item) => (
                    item.idDatabase === database._id && item.type === 'user'
                  ));
                  if (database.isBucket) {
                    fields.forEach((field) => {
                      const exists = fieldsUser?.find((item) => item.name === field.name);
                      if (exists) {
                        field.value = user._id;
                      }
                    });
                    fieldsUser.forEach((field) => {
                      const exists = fields?.find((item) => item.name === field.name);
                      if (!exists) {
                        fields.push({
                          name: field.name,
                          value: user._id,
                        });
                      }
                    });
                  } else {
                    fieldsUser.forEach((field) => {
                      body[field.name] = user._id;
                    });
                  }
                }
                if (endpoint.typeFunction === 'create') {
                  if (database?.isBucket) {
                    if (allowMultipleAttachment === 'multiple') {
                      if (e.target?.fileBucket?.files) {
                        for (const file of e.target.fileBucket.files) {
                          try {
                            await createSimulatorDatabaseBucket({
                              fields,
                              idEndpoint: idPostEndpoint,
                              loftyFile: file,
                              token: token || 'simulador',
                            });
                            toastr.success('Se agrego de manera exitosa');
                          } catch (error) {
                            toastr.error('Error no se subio el archivo');
                          }
                        }
                        // alert('Se agrego el dato de manera exitosa');
                      }
                    } else {
                      await createSimulatorDatabaseBucket({
                        fields,
                        idEndpoint: idPostEndpoint,
                        loftyFile: e.target?.fileBucket?.files[0],
                        token: token || 'simulador',
                      });
                      // alert('Se agrego el dato de manera exitosa');
                    }
                  } else {
                    await AddSimulatorDatabase({
                      data: body,
                      idEndpoint: idPostEndpoint,
                      token,
                    });
                    // alert('Se agrego el dato de manera exitosa');
                  }
                } else if (endpoint.typeFunction === 'customCreate') {
                  if (database?.isBucket) {
                    if (allowMultipleAttachment === 'multiple' && e.target?.fileBucket?.files) {
                      for (const filedata of e.target.fileBucket.files) {
                        try {
                          resFromQuery = await AddSimulatorBucketCustomDatabase({
                            body: fields,
                            file: filedata,
                            token,
                            idEndpoint: idPostEndpoint,
                          });
                          toastr.success('Agregado Archivo');
                        } catch (error) {
                          toastr.error('Error no se subio el archivo');
                        }
                      }
                    } else {
                      resFromQuery = await AddSimulatorBucketCustomDatabase({
                        body: fields,
                        file: e.target?.fileBucket?.files[0],
                        token,
                        idEndpoint: idPostEndpoint,
                      });
                    }
                  } else {
                    resFromQuery = await AddSimulatorCustomDatabase({
                      data: body,
                      idEndpoint: idPostEndpoint,
                      token,
                    });
                  }
                  // alert('Se agrego el dato de manera exitosa');
                }
                if (responseAddUpdateDb?.type === 'alert' && responseAddUpdateDb?.content) {
                  alert(`${responseAddUpdateDb?.content}`);
                } else if (responseAddUpdateDb?.type === 'function' && responseAddUpdateDb?.content) {
                  logicSimulatorFrontendCustom(responseAddUpdateDb?.content, globalVariables, localState, setGlobalState, setLocalState, []);
                } else if (responseAddUpdateDb?.type === 'redirect' && responseAddUpdateDb?.content) {
                  if (responseAddUpdateDb?.configs) {
                    let redirectUrl = responseAddUpdateDb.content;

                    const paramMatches = redirectUrl.match(/:[^/]+/g) || [];

                    paramMatches.forEach((paramWithColon) => {
                      const paramName = paramWithColon.substring(1);

                      const paramConfig = responseAddUpdateDb.configs.find((config) => config.name === paramName);

                      let valueToUse;

                      if (paramConfig) {
                        if (paramConfig.param === '_id') {
                          valueToUse = resFromQuery?.data?._id;
                        } else {
                          const dataValue = resFromQuery?.data?.data?.[paramConfig.param];
                          valueToUse = dataValue && dataValue !== '' ? dataValue : 'undefined';
                        }
                      } else {
                        const possibleValue = resFromQuery?.data?.data?.[paramName];
                        valueToUse = possibleValue && possibleValue !== '' ? possibleValue : 'undefined';
                      }

                      redirectUrl = redirectUrl.replace(`:${paramName}`, valueToUse);
                    });

                    window.location.href = redirectUrl;
                  } else {
                    window.location.href = responseAddUpdateDb.content;
                  }
                } else {
                  alert('Se agrego el dato de manera exitosa');
                }
              } catch (error) {
                if (error.info) {
                  alert(error.info);
                } else {
                  alert('Error al procesar la petición');
                }
              }
            }
          } else if (slugLoftyEqualTo(component, 'AuthSection')) {
            e.preventDefault();
            const email = e.target.loftyEmail?.value;
            const password = e.target.loftyPassword?.value;
            try {
              const res = await loginWithSimulatorUserEmail({
                email, password, idProject,
              });
              addTokenApp({
                token: res.token,
                user: res.user,
              });
              const traitAction = userAuthVariables.action;
              if (traitAction === 'message') {
                alert(userAuthVariables.alertMessage);
              } else if (traitAction === 'redirect') {
                if (userAuthVariables.redirect) {
                  window.location.href = userAuthVariables.redirect;
                }
              }
            } catch (error) {
              // console.log(error);
              if (error.info) {
                alert(error.info);
              } else {
                alert('Error al procesar la petición');
              }
            }
          } else if (slugLoftyEqualTo(component, 'RegisterSection')) {
            e.preventDefault();
            try {
              const fieldsDefault = searchComponentsBySlug(component.components, 'formInputRegisterUser');
              const fieldsBoolean = searchComponentsBySlug(component.components, 'formBooleanInputRegisterUser');
              const fieldsInputDefault = searchComponentsBySlug(component.components, 'formInputDefaultFieldRegisterUser');
              const fieldsPassword = searchComponentsBySlug(component.components, 'formPasswordDefaultFieldRegisterUser');
              const fieldsDate = searchComponentsBySlug(component.components, 'formDateInputDefaultFieldRegisterUser');
              const body = {};
              fieldsDefault.forEach((field) => {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              });
              fieldsBoolean.forEach((field) => {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              });
              fieldsInputDefault.forEach((field) => {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              });
              fieldsPassword.forEach((field) => {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              });
              fieldsDate.forEach((field) => {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              });
              body.email = e.target.loftyEmail?.value;
              body.password = e.target.loftyPassword?.value;
              const res = await createSimulatorUserEmail({
                idProject,
                body,
              });
              addTokenApp({
                token: res.token,
                user: res.user,
              });
              const traitAction = userAuthVariables.action;
              if (traitAction === 'message') {
                alert(userAuthVariables.alertMessage);
              } else if (traitAction === 'redirect') {
                if (userAuthVariables.redirect) {
                  window.location.href = userAuthVariables.redirect;
                }
              }
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Error al procesar la petición');
              }
            }
          } else if (slugLoftyEqualTo(component, 'formUpdateDatabase')) {
            e.preventDefault();
            const body = {};
            const inputString = searchComponentsBySlug(component.components, 'formInputUpdateDatabase');
            const inputDate = searchComponentsBySlug(component.components, 'formDateInputUpdateDatabase');
            const inputNumber = searchComponentsBySlug(component.components, 'formNumberInputUpdateDatabase');
            const inputBoolean = searchComponentsBySlug(component.components, 'formBooleanInputUpdateDatabase');
            const inputSelect = searchComponentsBySlug(component.components, 'formSelectRelationUpdate');
            const inputSelectUpdate = searchComponentsBySlug(component.components, 'formSelectRelation');
            const inputSelectUser = searchComponentsBySlug(component.components, 'formSelectUserUpdate');
            const inputSelectUserUpdate = searchComponentsBySlug(component.components, 'formSelectUser');
            const inputSelectStringNormal = searchComponentsBySlug(component.components, 'formSelectAdd');
            const inputTextarea = searchComponentsBySlug(component.components, 'formTextarea');
            inputString.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputTextarea.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputSelectStringNormal.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputDate.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputNumber.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputBoolean.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputSelect.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputSelectUpdate.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputSelectUser.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            inputSelectUserUpdate.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
              }
            });
            // FIND MICH
            if (relationParamData?.length > 0) {
              relationParamData.forEach((field) => {
                if (field.state === 'useparams') {
                  const resParam = params[field.param];
                  body[field.relationInput.name] = resParam;
                  // fields.push({
                  //   name: field.relationInput.name,
                  //   value: resParam,
                  // });
                }
              });
            }
            if (cartParamData?.length > 0) {
              const arrayOfParams = cartParamData;
              const findAllDBParams = arrayOfParams?.filter((index) => (index.state === 'dbfield'));
              const findAllVariableParams = arrayOfParams?.filter((index) => (index.state === 'variable'));
              if (findAllVariableParams?.length > 0) {
                findAllVariableParams.forEach((index) => {
                  const findVariable = findVariableExists(index.param, localState, globalVariables);
                  if (findVariable === 'isGlobal') {
                    const resSearch = searchMyValue(index.param, globalVariables);
                    body[index.paramendpoint.value] = resSearch;
                    // fields.push({
                    //   name: index.paramendpoint.value,
                    //   value: resSearch,
                    // });
                  } else if (findVariable === 'isLocal') {
                    const resSearch = searchMyValue(index.param, localState);
                    body[index.paramendpoint.value] = resSearch;
                    // fields.push({
                    //   name: index.paramendpoint.value,
                    //   value: resSearch,
                    // });
                  }
                });
              }
              if (findAllDBParams?.length > 0) {
                findAllDBParams.forEach((index) => {
                  if (rootReaderData[index.param]) {
                    body[index.paramendpoint.value] = rootReaderData[index.param];
                    // fields.push({
                    //   name: index.paramendpoint.value,
                    //   value: rootReaderData[index.param],
                    // });
                  }
                });
              }
            }
            try {
              let resFromQuery = [];
              const endpoint = dataGenerate?.endpoints?.find((item) => item._id === idPutEndpoint);
              const database = dataGenerate?.databases?.find((item) => item._id === endpoint.idDatabase);
              if (useCurrentUserAddDatabase === 'usecurrent') {
                const fieldsUser = dataGenerate.fieldsDatabase?.filter((item) => (
                  item.idDatabase === database._id && item.type === 'user'
                ));
                fieldsUser.forEach((field) => {
                  body[field.name] = user._id;
                });
              }
              if (endpoint.typeFunction === 'customUpdate') {
                const arrayOfParams = relationParam;
                const findAllDBParams = arrayOfParams?.filter((index) => (index.state === 'dbfield'));
                const findAllPageParams = arrayOfParams?.filter((index) => (index.state === 'useparams'));
                const findAllVariableParams = arrayOfParams?.filter((index) => (index.state === 'variable'));
                const findAllStaticValues = arrayOfParams?.filter((index) => (index.state === 'static'));
                const arrangedBody = {};
                if (findAllVariableParams?.length > 0) {
                  findAllVariableParams.forEach((index) => {
                    const findVariable = findVariableExists(index.param, localState, globalVariables);
                    if (findVariable === 'isGlobal') {
                      arrangedBody[index.paramendpoint.value] = searchMyValue(index.param, globalVariables);
                    } else if (findVariable === 'isLocal') {
                      arrangedBody[index.paramendpoint.value] = searchMyValue(index.param, localState);
                    }
                  });
                }
                if (findAllStaticValues?.length > 0) {
                  findAllStaticValues.forEach((index) => {
                    arrangedBody[index.paramendpoint.value] = index?.param || index?.value;
                  });
                }
                if (findAllPageParams?.length > 0) {
                  findAllPageParams.forEach((index) => {
                    arrangedBody[index.paramendpoint.value] = params[index.param];
                  });
                }
                if (findAllDBParams?.length > 0) {
                  findAllDBParams.forEach((index) => {
                    if (rootReaderData[index.param]) {
                      arrangedBody[index.paramendpoint.value] = rootReaderData[index.param];
                    }
                  });
                }
                resFromQuery = await UpdateSimulatorCustomDatabase({
                  data: body,
                  idEndpoint: idPutEndpoint,
                  token,
                  paramInfo: arrangedBody,
                });
              } else {
                await updateSimulatorDatabase({
                  _id: relationParam,
                  data: body,
                  idEndpoint: idPutEndpoint,
                  token,
                });
              }
              if (responseAddUpdateDb?.type === 'alert' && responseAddUpdateDb?.content) {
                alert(`${responseAddUpdateDb?.content}`);
              } else if (responseAddUpdateDb?.type === 'function' && responseAddUpdateDb?.content) {
                logicSimulatorFrontendCustom(responseAddUpdateDb?.content, globalVariables, localState, setGlobalState, setLocalState, []);
              } else if (responseAddUpdateDb?.type === 'redirect' && responseAddUpdateDb?.content) {
                if (responseAddUpdateDb?.configs) {
                  let redirectUrl = responseAddUpdateDb.content;

                  const paramMatches = redirectUrl.match(/:[^/]+/g) || [];

                  paramMatches.forEach((paramWithColon) => {
                    const paramName = paramWithColon.substring(1);

                    const paramConfig = responseAddUpdateDb.configs.find((config) => config.name === paramName);

                    let valueToUse;

                    if (paramConfig) {
                      if (paramConfig.param === '_id') {
                        valueToUse = resFromQuery?.data?._id;
                      } else {
                        const dataValue = resFromQuery?.data?.data?.[paramConfig.param];
                        valueToUse = dataValue && dataValue !== '' ? dataValue : 'undefined';
                      }
                    } else {
                      const possibleValue = resFromQuery?.data?.data?.[paramName];
                      valueToUse = possibleValue && possibleValue !== '' ? possibleValue : 'undefined';
                    }

                    redirectUrl = redirectUrl.replace(`:${paramName}`, valueToUse);
                  });

                  window.location.href = redirectUrl;
                } else {
                  window.location.href = responseAddUpdateDb.content;
                }
              } else {
                alert('Se agrego el dato de manera exitosa');
              }
            } catch (error) {
              // code
              console.log('error al actualizar ', error);
            }
          } else if (slugLoftyEqualTo(component, 'mainFormForgotPassword')) {
            // const emailComp = searchComponentsBySlug(component.components, 'formInputEmailForgotPass');
            // const email = emailComp.map((field) => (e.target[field.attributes?.name].value || e.target[field.name].value));
            const email = e.target.emailReqPasswordReset?.value;
            try {
              createSimulatorReset({
                email, token,
              });
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Error al procesar la petición');
              }
            }
          } else if (slugLoftyEqualTo(component, 'ResetPasswordSection')) {
            // const newPass = searchComponentsBySlug(component.components, 'NewPasswordUser');
            // const confirmPass = searchComponentsBySlug(component.components, 'ConfirmPasswordUser');
            const newPassValue = e.target.newPassword?.value;
            const confirmPassValue = e.target.confirmPassword?.value;
            try {
              const paramFilter = component.attributes.filterParam;
              verifySimulatorReset(
                {
                  idProject,
                  password: newPassValue,
                  confirmPassword: confirmPassValue,
                  token,
                  tokenReset: params[paramFilter],
                },
              );
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Error al procesar la petición');
              }
            }
          } else if (slugLoftyEqualTo(component, 'calendarForm')) {
            e.preventDefault();
            submitCalendar(e);
          } else if (slugLoftyEqualTo(component, 'UpdatePasswordSection')) {
            const newPassUpdate = e.target.newUpdatePassword?.value;
            const confirmNewPassUpdate = e.target.confirmUpdatePassword?.value;
            e.preventDefault();
            try {
              changePasswordSimulatorUser({
                newPassword: newPassUpdate,
                confirmNewPassword: confirmNewPassUpdate,
                token,
                idProject,
                tokenLoftyApps: token,
              });
              if (userAuthVariables.action === 'redirect') {
                window.location.href = userAuthVariables.redirectPage;
              } else if (userAuthVariables.action === 'message') {
                alert(userAuthVariables.alertMessage);
              }
            } catch (error) {
              if (error.info) {
                alert(error.info);
              } else {
                alert('Error al procesar la petición');
              }
            }
          } else if (slugLoftyEqualTo(component, 'formUpdateUserInfo')) {
            e.preventDefault();
            const body = {};
            const inputLoftyName = searchComponentsBySlug(component.components, 'formInputDefaultFieldUpdateUser');
            const inputString = searchComponentsBySlug(component.components, 'formInputDatabase');
            const inputDate = searchComponentsBySlug(component.components, 'formDateInputDatabase');
            const inputNumber = searchComponentsBySlug(component.components, 'formNumberInputDatabase');
            const inputBoolean = searchComponentsBySlug(component.components, 'formBooleanInputDatabase');
            const selectRelation = searchComponentsBySlug(component.components, 'formSelectRelation');
            const selectUser = searchComponentsBySlug(component.components, 'formSelectUser');
            const inputTextrich = searchComponentsBySlug(component.components, 'formRichTextDatabase');
            const fields = [];
            inputLoftyName.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            inputString.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            inputDate.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            inputNumber.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            inputBoolean.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            selectRelation.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            selectUser.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes?.name] = e.target[field.attributes?.name].value;
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            inputTextrich.forEach((field) => {
              if (field.attributes?.name) {
                body[field.attributes.name] = JSON.parse(e.target[field.attributes?.name].value);
                fields.push({
                  name: field.attributes?.name,
                  value: e.target[field.attributes?.name].value,
                });
              }
            });
            try {
              // console.log(body);
              // console.log(component);
              await updateSimulatorUserProject({
                body,
                idProject,
                token,
                tokenLoftyApps: token,
              });
              if (component.actionLogout === 'redirect') {
                window.location.href = component.redirectPage;
              } else if (component.actionLogout === 'message') {
                alert(component.alertMessage);
              }
              // console.log('hey');
            } catch (error) {
              alert('Error al procesar la petición');
            }
          }
        }}
      >
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </form>
    );
  }
  // label
  if (componentLabel({component})) {
    return (
      <label id={component.attributes?.id} className={manageClasses(component)}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </label>
    );
  }
  // input
  if (componentInput({component})) {
    if (slugLoftyEqualTo(component, 'formInput')) {
      if (inputStateVariable?.variableId) {
        if (inputStateVariable.variableLocation === 'isLocal') {
          return (
            <input
              name={component.attributes?.name}
              className={manageClasses(component)}
              id={component.attributes?.id}
              type={inputStateVariable.typeOfInput}
              placeholder={component.attributes?.placeholder}
              onChange={
                (e) => updateLocalVariable(inputStateVariable?.variableId, e.target.value, localState, setLocalState, refurbishEndpoint, variableForEndpoint)
              }
              required={component?.attributes?.required || false}
            />
          );
        } else if (inputStateVariable.variableLocation === 'isGlobal') {
          return (
            <input
              name={component.attributes?.name}
              className={manageClasses(component)}
              id={component.attributes?.id}
              type={inputStateVariable.typeOfInput}
              placeholder={component.attributes?.placeholder}
              onChange={
                (e) => updateGlobalVariable(inputStateVariable?.variableId, e.target.value, globalVariables, setGlobalState, refurbishEndpoint, variableForEndpoint)
              }
              required={component?.attributes?.required || false}
            />
          );
        }
        return (
          <input
            name={component.attributes?.name}
            className={manageClasses(component)}
            id={component.attributes?.id}
            type={component.attributes?.type}
            placeholder={component.attributes?.placeholder}
            required={component?.attributes?.required || false}
          />
        );
      }
    }
    const currentInput = component.attributes?.name;
    // if (user !== undefined) {
    //   console.log('');
    //   console.log(user);
    //   console.log(userInfo);
    // }
    // if (user !== undefined) {
    //   valueInput = user[currentInput];
    //   handlerUserUpdate(currentInput, valueInput);
    // }
    // console.log(valueInput);
    if (component.attributes?.type === 'password') {
      return (
        <input
          name={component.attributes?.name}
          className={manageClasses(component)}
          id={component.attributes?.id}
          type={component.attributes?.type}
          placeholder={component.attributes?.placeholder}
        />
      );
    }
    if (user && userInfo !== undefined && user[currentInput] && allowMultipleAttachment === 'handleUpdateUserInfo') {
      return (
        <input
          name={component.attributes?.name}
          className={manageClasses(component)}
          type={component.attributes?.type}
          id={component.attributes?.id}
          multiple={allowMultipleAttachment === 'multiple'}
          placeholder={component.attributes?.placeholder}
          defaultValue={userInfo[currentInput]}
          defaultChecked={userInfo[currentInput]}
        />
      );
    }
    return (
      <input
        name={component.attributes?.name}
        className={manageClasses(component)}
        type={component.attributes?.type}
        id={component.attributes?.id}
        multiple={allowMultipleAttachment === 'multiple'}
        placeholder={component.attributes?.placeholder}
        required={component?.attributes?.required || false}
      />
    );
  }
  // select
  if (componentSelect({component})) {
    if (slugLoftyEqualTo(component, 'selectComponent')) {
      const captureOptionsData = component?.attributes?.optionsRender;
      if (captureOptionsData?.length > 0) {
        if (component?.variableHandle === 'local' && component?.localVars) {
          return (
            <select
              className={manageClasses(component)}
              id={component.attributes?.id}
              onChange={
                (e) => updateLocalVariable(component?.localVars, e.target.value, localState, setLocalState, refurbishEndpoint, variableForEndpoint)
              }
              value={searchMyValue(component?.localVars, localState)}
            >
              {captureOptionsData.map((index) => (
                <option
                  value={index?.value}
                >
                  {index?.label}
                </option>
              ))}
            </select>
          );
        } else if (component?.variableHandle === 'global' && component?.globalVars) {
          return (
            <select
              className={manageClasses(component)}
              id={component.attributes?.id}
              onChange={
                (e) => updateGlobalVariable(component?.globalVars, e.target.value, globalVariables, setGlobalState, refurbishEndpoint, variableForEndpoint)
              }
              value={searchMyValue(component?.globalVars, globalVariables)}
            >
              {captureOptionsData.map((index) => (
                <option
                  value={index?.value}
                >
                  {index?.label}
                </option>
              ))}
            </select>
          );
        }
      }
      return null;
    }
    if (slugLoftyEqualTo(component, 'formSelectRelation')) {
      try {
        const endpoint = dataGenerate?.endpoints?.find((item) => (
          item._id === idPostEndpoint
        ));
        const fieldDatabase = dataGenerate?.fieldsDatabase?.find((item) => (
          item.idDatabase === endpoint?.idDatabase && item.name === component.attributes?.name
        ));

        let endpointRelation = '';
        let endpointType = '';
        let originDatabase = '';
        if (fieldDatabase?.relationEndpoint) {
          endpointRelation = dataGenerate?.endpoints?.find((item) => (
            item.idDatabase === fieldDatabase.relationDatabase && (item._id === fieldDatabase?.relationEndpoint || item?.typeFunction === 'list')
          ));
          endpointType = endpointRelation?.typeFunction;
          originDatabase = fieldDatabase.relationDatabase;
        } else if (endpoint?.typeFunction === 'customCreate') {
          const searchForParam = dataGenerate?.paramsEndpoint?.find((item) => (item._id === component?.id && item.type === 'relation'));
          endpointRelation = dataGenerate?.endpoints?.find((item) => (
            item?.idDatabase === searchForParam?.relationDatabase && searchForParam?.relationDatabase
          ));
          endpointType = endpointRelation?.typeFunction;
          originDatabase = searchForParam?.relationDatabase;
        }

        return (
          <select
            name={component.attributes?.name}
            className={manageClasses(component)}
            id={component.attributes?.id}
            required={component?.attributes?.required || false}
          >
            <OptionsDatabase
              dataGenerate={dataGenerate}
              token={token}
              idDatabase={originDatabase}
              idEndpoint={endpointRelation?._id}
              type={endpointType}
            />
          </select>
        );
      } catch (error) {
        toastr.error('Error. El Servicio no esta disponible');
        return null;
      }
    }
    return (
      <select
        name={component.attributes?.name}
        className={manageClasses(component)}
        id={component.attributes?.id}
        required={component?.attributes?.required || false}
      >
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              token={token}
              allowMultipleAttachment={allowMultipleAttachment}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </select>
    );
  }
  if (
    slugLoftyEqualTo(component, 'formSelectRelationOption') || slugLoftyEqualTo(component, 'formSelectUserOption')
    || slugLoftyEqualTo(component, 'formSelectRelationOptionUpdate') || slugLoftyEqualTo(component, 'formSelectUserOptionUpdate')
    || slugLoftyEqualTo(component, 'formSelectOptionsAdd')
  ) {
    return (
      <option
        className={manageClasses(component)}
        id={component.attributes?.id}
        value={component.attributes?.value}
      >
        {
          component.components?.map((item) => {
            if (item?.type === 'textnode' && item?.content) {
              return item.content;
            } else {
              return (
                <DynamicComponent
                  component={item}
                  dataEndpoints={dataEndpoints}
                  idProject={idProject}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  objectReader={objectReader}
                  relationParam={relationParam}
                  deletetokenApp={deletetokenApp}
                  dataGenerate={dataGenerate}
                  // plugin del calnedario
                  activeMonth={activeMonth}
                  changeLastMonth={changeLastMonth}
                  changeNextMonth={changeNextMonth}
                  listHours={listHours}
                  now={now}
                  selectDate={selectDate}
                  changeHour={changeHour}
                  submitCalendar={submitCalendar}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  user={user}
                  useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                  allowMultipleAttachment={allowMultipleAttachment}
                  token={token}
                  idGetEnpointSwiper1={idGetEnpointSwiper1}
                  idGetEnpointSwiper2={idGetEnpointSwiper2}
                  idGetEnpointSwiper3={idGetEnpointSwiper3}
                  relationParamData={relationParamData}
                  userInfo={userInfo}
                  databaseSelectionForFields={databaseSelectionForFields}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  globalVars={globalVars}
                  localVars={localVars}
                  // Input With Variables
                  inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={rootReaderData}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  responseAddUpdateDb={responseAddUpdateDb}
                  cartParamData={cartParamData}
                  carouselOriginState={carouselOriginState}
                />
              );
            }
          })
        }
      </option>
    );
  }
  // button
  if (componentButton({component})) {
    /** Plugin calendar */
    if (slugLoftyEqualTo(component, 'calendarLeftArrow') || slugLoftyEqualTo(component, 'calendarRightArrow')) {
      return (
        <ItemCalendar
          component={component}
          activeMonth={activeMonth}
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          dataEndpoints={dataEndpoints}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idProject={idProject}
          idPutEndpoint={idPutEndpoint}
          nameFiledReader={nameFiledReader}
          objectReader={objectReader}
          params={params}
          relationParam={relationParam}
          token={token}
          changeLastMonth={changeLastMonth}
          changeNextMonth={changeNextMonth}
          now={now}
          selectDate={selectDate}
          listHours={listHours}
          changeHour={changeHour}
          submitCalendar={submitCalendar}
          deletetokenApp={deletetokenApp}
          dataGenerate={dataGenerate}
          user={user}
          userInfo={userInfo}
          databaseSelectionForFields={databaseSelectionForFields}
          // STATES
          globalVariables={globalVariables}
          setGlobalState={setGlobalState}
          localState={localState}
          setLocalState={setLocalState}
          globalVars={globalVars}
          localVars={localVars}
          // For Read Db with dbField data
          rootReaderData={rootReaderData}
          refurbishEndpoint={refurbishEndpoint}
          variableForEndpoint={variableForEndpoint}
          responseAddUpdateDb={responseAddUpdateDb}
          cartParamData={cartParamData}
          carouselOriginState={carouselOriginState}
        />
      );
    }

    if (slugLoftyEqualTo(component, 'modalButton')) {
      return (
        <button
          name={component.attributes?.name}
          id={component.attributes?.id}
          className={manageClasses(component)}
          data-bs-target={component.attributes?.['data-bs-target']}
          data-bs-toggle={component.attributes?.['data-bs-toggle']}
          data-target={component.attributes?.['data-target']}
          data-toggle={component.attributes?.['data-toggle']}
        >
          {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              nameFiledReader={nameFiledReader}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              objectReader={objectReader}
              relationParam={objectReader}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
        </button>
      );
    }
    if (slugLoftyEqualTo(component, 'exportpdfButton')) {
      return (
        <button
          name={component.attributes?.name}
          id={component.attributes?.id}
          className={manageClasses(component)}
          onClick={() => {
            if (component?.attributes?.idForExport) {
              const elementFromID = document.getElementById(component?.attributes?.idForExport);
              const options = {
                margin: 1,
                filename: `${component?.attributes?.idForExport}_export.pdf`,
                image: {type: 'jpeg', quality: 0.98},
                html2canvas: {scale: 2},
                jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'},
              };
              html2pdf().set(options).from(elementFromID).save();
            }
          }}
        >
          {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              nameFiledReader={nameFiledReader}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              objectReader={objectReader}
              relationParam={objectReader}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
        </button>
      );
    }
    /** Fin del plugin del calendar */
    if (slugLoftyEqualTo(component, 'standardButton')) {
      // console.log(component);
      if (component?.buttonUtility && component?.buttonUtility === 'addlist') {
        const objectInfo = dataEndpoints?.find((item) => item.id === component.toAddListFromReadDB);
        const seekPossibleRelation = component?.dataRelationVar || null;
        let dataToAdd = [];
        if (!objectInfo) {
          return null;
        }
        if (!objectInfo?.data) {
          return null;
        }
        if (objectInfo.endpoint.typeFunction === 'list' || objectInfo.endpoint.typeFunction === 'customList') {
          if (rootReaderData) {
            dataToAdd = rootReaderData;
          } else {
            dataToAdd = objectInfo.data?.data;
          }
          return (
            <button
              type={component.attributes?.type}
              name={component.attributes?.name}
              id={component.attributes?.id}
              className={manageClasses(component)}
              onClick={() => {
                addItemToArray(component?.arrayOptionState, dataToAdd, localState, globalVariables, setLocalState, setGlobalState, seekPossibleRelation);
              }}
            >
              {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idProject={idProject}
                params={params}
                nameFiledReader={nameFiledReader}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                objectReader={objectReader}
                relationParam={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                allowMultipleAttachment={allowMultipleAttachment}
                token={token}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
            </button>
          );
        } else if (objectInfo.endpoint.typeFunction === 'one' || objectInfo.endpoint.typeFunction === 'customOne') {
          if (rootReaderData) {
            dataToAdd = rootReaderData;
          } else {
            dataToAdd = objectInfo.data?.data;
          }
          return (
            <button
              type={component.attributes?.type}
              name={component.attributes?.name}
              id={component.attributes?.id}
              className={manageClasses(component)}
              onClick={() => {
                addItemToArray(component?.arrayOptionState, dataToAdd, localState, globalVariables, setLocalState, setGlobalState, seekPossibleRelation);
              }}
            >
              {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idProject={idProject}
                params={params}
                nameFiledReader={nameFiledReader}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                objectReader={objectReader}
                relationParam={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                allowMultipleAttachment={allowMultipleAttachment}
                token={token}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
            </button>
          );
        }
        return null;
      }
      if (component?.buttonUtility && component?.buttonUtility === 'function') {
        const logicData = component?.attributes?.customPageFunction?.arrayLogic[0]?.blocks?.find((index) => (index.type === 'main_block'));
        if (logicData?.type === 'main_block') {
          return (
            <button
              type={component.attributes?.type}
              name={component.attributes?.name}
              id={component.attributes?.id}
              className={manageClasses(component)}
              onClick={() => {
                logicSimulatorFrontendCustom(logicData, globalVariables, localState, setGlobalState, setLocalState, []);
              }}
            >
              {
            component?.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idProject={idProject}
                params={params}
                nameFiledReader={nameFiledReader}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                objectReader={objectReader}
                relationParam={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                allowMultipleAttachment={allowMultipleAttachment}
                token={token}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
            </button>
          );
        } else {
          return (
            <button
              type={component.attributes?.type}
              name={component.attributes?.name}
              id={component.attributes?.id}
              className={manageClasses(component)}
            >
              {
                component?.components?.map((item) => (
                  <DynamicComponent
                    component={item}
                    dataEndpoints={dataEndpoints}
                    idProject={idProject}
                    params={params}
                    nameFiledReader={nameFiledReader}
                    idDeleteEndpoint={idDeleteEndpoint}
                    idGetEndpoint={idGetEndpoint}
                    idPostEndpoint={idPostEndpoint}
                    idPutEndpoint={idPutEndpoint}
                    objectReader={objectReader}
                    relationParam={objectReader}
                    deletetokenApp={deletetokenApp}
                    dataGenerate={dataGenerate}
                    // plugin del calnedario
                    activeMonth={activeMonth}
                    changeLastMonth={changeLastMonth}
                    changeNextMonth={changeNextMonth}
                    listHours={listHours}
                    now={now}
                    selectDate={selectDate}
                    changeHour={changeHour}
                    submitCalendar={submitCalendar}
                    addTokenApp={addTokenApp}
                    addUserApp={addUserApp}
                    user={user}
                    useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                    allowMultipleAttachment={allowMultipleAttachment}
                    token={token}
                    userInfo={userInfo}
                    databaseSelectionForFields={databaseSelectionForFields}
                    // STATES
                    globalVariables={globalVariables}
                    setGlobalState={setGlobalState}
                    localState={localState}
                    setLocalState={setLocalState}
                    globalVars={globalVars}
                    localVars={localVars}
                    // Input With Variables
                    inputStateVariable={inputStateVariable}
                    // For Read Db with dbField data
                    rootReaderData={rootReaderData}
                    refurbishEndpoint={refurbishEndpoint}
                    variableForEndpoint={variableForEndpoint}
                    responseAddUpdateDb={responseAddUpdateDb}
                    cartParamData={cartParamData}
                    carouselOriginState={carouselOriginState}
                  />
                ))
              }
            </button>
          );
        }
      }
      if (component?.buttonUtility && component?.buttonUtility === 'setData') {
        const objectInfo = dataEndpoints?.find((item) => item.id === component.toAddListFromReadDB);
        // const seekPossibleRelation = component?.dataRelationVar || null;
        let dataToAdd = [];
        if (!objectInfo) {
          return null;
        }
        if (!objectInfo?.data) {
          return null;
        }
        if (objectInfo.endpoint.typeFunction === 'list' || objectInfo.endpoint.typeFunction === 'customList') {
          if (rootReaderData) {
            dataToAdd = rootReaderData;
          }
          return (
            <button
              type={component.attributes?.type}
              name={component.attributes?.name}
              id={component.attributes?.id}
              className={manageClasses(component)}
              onClick={() => {
                setDataOfVariable(component?.arrayOptionState, dataToAdd, localState, globalVariables, setLocalState, setGlobalState);
              }}
            >
              {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idProject={idProject}
                params={params}
                nameFiledReader={nameFiledReader}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                objectReader={objectReader}
                relationParam={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                allowMultipleAttachment={allowMultipleAttachment}
                token={token}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
            </button>
          );
        } else if (objectInfo.endpoint.typeFunction === 'one' || objectInfo.endpoint.typeFunction === 'customOne') {
          if (rootReaderData) {
            dataToAdd = rootReaderData;
          } else {
            dataToAdd = objectInfo.data?.data;
          }
          return (
            <button
              type={component.attributes?.type}
              name={component.attributes?.name}
              id={component.attributes?.id}
              className={manageClasses(component)}
              onClick={() => {
                setDataOfVariable(component?.arrayOptionState, dataToAdd, localState, globalVariables, setLocalState, setGlobalState);
              }}
            >
              {
            component.components?.map((item) => (
              <DynamicComponent
                component={item}
                dataEndpoints={dataEndpoints}
                idProject={idProject}
                params={params}
                nameFiledReader={nameFiledReader}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                objectReader={objectReader}
                relationParam={objectReader}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                allowMultipleAttachment={allowMultipleAttachment}
                token={token}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
            </button>
          );
        }
        return null;
      }
      return (
        <button
          type={component.attributes?.type}
          name={component.attributes?.name}
          id={component.attributes?.id}
          className={manageClasses(component)}
        >
          {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              nameFiledReader={nameFiledReader}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              objectReader={objectReader}
              relationParam={objectReader}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
        </button>
      );
    }
    if (slugLoftyEqualTo(component, 'loginWithGoogleButton')) {
      return (
        <GoogleLoginComponent
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          component={component}
          rootReaderData={rootReaderData}
          dataEndpoints={dataEndpoints}
          globalVariables={globalVariables}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idProject={idProject}
          idPutEndpoint={idPutEndpoint}
          localState={localState}
          nameFiledReader={nameFiledReader}
          objectReader={objectReader}
          params={params}
          refurbishEndpoint={refurbishEndpoint}
          relationParam={relationParam}
          setGlobalState={setGlobalState}
          setLocalState={setLocalState}
          token={token}
          variableForEndpoint={variableForEndpoint}
          dataGenerate={dataGenerate}
          carouselOriginState={carouselOriginState}
        />
      );
    }
    return (
      <button
        type={component.attributes?.type}
        name={component.attributes?.name}
        id={component.attributes?.id}
        className={manageClasses(component)}
        onClick={async () => {
          if (slugLoftyEqualTo(component, 'deletionButton')) {
            try {
              await deleteSimulatorDatabase({
                idEndpoint: idDeleteEndpoint,
                _id: relationParam,
                token,
              });
            } catch (error) {
              // code
            }
          } else if (slugLoftyEqualTo(component, 'eliminateRead')) {
            try {
              await deleteSimulatorDatabase({
                idEndpoint: idDeleteEndpoint,
                _id: objectReader._id,
                token,
              });
            } catch (error) {
              // code
            }
          } else if (slugLoftyEqualTo(component, 'logoutUserButtonComponent')) {
            deletetokenApp();
            if (component.actionLogout === 'redirect') {
              window.location.href = component.redirectPage;
            } else if (component.actionLogout === 'message') {
              alert(component.alertMessage);
            }
          }
        }}
      >
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              nameFiledReader={nameFiledReader}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              objectReader={objectReader}
              relationParam={objectReader}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </button>
    );
  }
  // cuando sea contenido
  if (slugLoftyEqualTo(component, 'basicText')) {
    return (
      <div className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              idProject={idProject}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </div>
    );
  }
  // componente textarea
  if (slugLoftyEqualTo(component, 'formTextarea')) {
    return (
      <textarea id={component.attributes?.id} className={manageClasses(component)}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </textarea>
    );
  }
  // componente de campo para la escritura
  if (
    slugLoftyEqualTo(component, 'formRichTextDatabase')
    || slugLoftyEqualTo(component, 'formRichTextDatabaseUpdate')
  ) {
    return (
      <EditorJs
        id={component.attributes?.id}
        className={manageClasses(component)}
        idProject={idProject}
        name={component.attributes?.name}
        token={token}
      />
    );
  }
  // componente span
  if (!component.sluglofty && component.type === 'textnode') {
    if (haveContent(component.content)) {
      return <>{component.content}</>;
    }
    return null;
  }

  if (!component.slugLofty && component.tagName === 'b') {
    return (
      <b className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </b>
    );
  }
  // componente span
  if (
    (!component.slugLofty && component.tagName === 'span')
    || slugLoftyEqualTo(component, 'formCheckboxSpan') || slugLoftyEqualTo(component, 'hamburgerSpan')
    || slugLoftyEqualTo(component, 'hamburgerSpanClose')
  ) {
    return (
      <span className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </span>
    );
  }
  // componente strike
  if (!component.slugLofty && component.tagName === 'strike') {
    return (
      <strike className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </strike>
    );
  }
  // componente u
  if (!component.slugLofty && component.tagName === 'u') {
    return (
      <u className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </u>
    );
  }
  // componente i
  if (!component.slugLofty && component.tagName === 'i') {
    return (
      <i className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </i>
    );
  }

  // p without slugLofty
  if (!component.slugLofty && component.tagName === 'p') {
    try {
      return (
        <p className={manageClasses(component)} id={component.attributes?.id}>
          {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              idProject={idProject}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
        </p>
      );
    } catch (error) {
      //
    }
  }
  // componente br
  if (!component.slugLofty && component.tagName === 'br') {
    return (
      <br className={manageClasses(component)} id={component.attributes?.id} />
    );
  }

  // componente a
  if (
    (!component.slugLofty && component.type === 'link')
  ) {
    if (component.pageWithParams && component.withParams === 'local') {
      const urlRedirect = component.pageWithParams;
      const params = component.attributes.paramsPage;
      let res = urlRedirect;
      // console.log(component);
      if (params) {
        params.forEach((index) => {
          const comp = `:${index.name}`;
          res = res.replace(comp, index.value);
        });
        if (res.match(/:/g)) {
          res = res.replace(/:[a-zA-Z0-9_]+/g, 'null');
        }
      } else {
        const params = component.attributes?.hrefParams;
        params?.forEach((index) => {
          const comp = index.url;
          if (objectReader) {
            res = res.replace(comp, objectReader[index.param]);
          }
        });
        if (res.match(/:/g)) {
          res = res.replace(/:[a-zA-Z0-9_]+/g, 'null');
        }
      }
      return (
        <a
          target={component.attributes?.target}
          className={manageClasses(component)}
          id={component.attributes?.id}
          href={res}
        >
          {
        component.components?.map((item) => (
          <DynamicComponent
            idProject={idProject}
            component={item}
            dataEndpoints={dataEndpoints}
            params={params}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={idGetEndpoint}
            idPostEndpoint={idPostEndpoint}
            idPutEndpoint={idPutEndpoint}
            nameFiledReader={nameFiledReader}
            objectReader={objectReader}
            relationParam={relationParam}
            deletetokenApp={deletetokenApp}
            dataGenerate={dataGenerate}
            // plugin del calnedario
            activeMonth={activeMonth}
            changeLastMonth={changeLastMonth}
            changeNextMonth={changeNextMonth}
            listHours={listHours}
            now={now}
            selectDate={selectDate}
            changeHour={changeHour}
            submitCalendar={submitCalendar}
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            user={user}
            useCurrentUserAddDatabase={useCurrentUserAddDatabase}
            allowMultipleAttachment={allowMultipleAttachment}
            token={token}
            idGetEnpointSwiper1={idGetEnpointSwiper1}
            idGetEnpointSwiper2={idGetEnpointSwiper2}
            idGetEnpointSwiper3={idGetEnpointSwiper3}
            relationParamData={relationParamData}
            userInfo={userInfo}
            databaseSelectionForFields={databaseSelectionForFields}
            // STATES
            globalVariables={globalVariables}
            setGlobalState={setGlobalState}
            localState={localState}
            setLocalState={setLocalState}
            globalVars={globalVars}
            localVars={localVars}
            // Input With Variables
            inputStateVariable={inputStateVariable}
            // For Read Db with dbField data
            rootReaderData={rootReaderData}
            refurbishEndpoint={refurbishEndpoint}
            variableForEndpoint={variableForEndpoint}
            responseAddUpdateDb={responseAddUpdateDb}
            cartParamData={cartParamData}
            carouselOriginState={carouselOriginState}
          />
        ))
      }
        </a>
      );
    } else {
      return (
        <a
          target={component.attributes?.target}
          className={manageClasses(component)}
          id={component.attributes?.id}
          href={component.attributes?.href}
          type={component.attributes?.type}
        >
          {
            component.components?.map((item) => (
              <DynamicComponent
                idProject={idProject}
                component={item}
                dataEndpoints={dataEndpoints}
                params={params}
                idDeleteEndpoint={idDeleteEndpoint}
                idGetEndpoint={idGetEndpoint}
                idPostEndpoint={idPostEndpoint}
                idPutEndpoint={idPutEndpoint}
                nameFiledReader={nameFiledReader}
                objectReader={objectReader}
                relationParam={relationParam}
                deletetokenApp={deletetokenApp}
                dataGenerate={dataGenerate}
                // plugin del calnedario
                activeMonth={activeMonth}
                changeLastMonth={changeLastMonth}
                changeNextMonth={changeNextMonth}
                listHours={listHours}
                now={now}
                selectDate={selectDate}
                changeHour={changeHour}
                submitCalendar={submitCalendar}
                addTokenApp={addTokenApp}
                addUserApp={addUserApp}
                user={user}
                useCurrentUserAddDatabase={useCurrentUserAddDatabase}
                allowMultipleAttachment={allowMultipleAttachment}
                token={token}
                idGetEnpointSwiper1={idGetEnpointSwiper1}
                idGetEnpointSwiper2={idGetEnpointSwiper2}
                idGetEnpointSwiper3={idGetEnpointSwiper3}
                relationParamData={relationParamData}
                userInfo={userInfo}
                databaseSelectionForFields={databaseSelectionForFields}
                // STATES
                globalVariables={globalVariables}
                setGlobalState={setGlobalState}
                localState={localState}
                setLocalState={setLocalState}
                globalVars={globalVars}
                localVars={localVars}
                // Input With Variables
                inputStateVariable={inputStateVariable}
                // For Read Db with dbField data
                rootReaderData={rootReaderData}
                refurbishEndpoint={refurbishEndpoint}
                variableForEndpoint={variableForEndpoint}
                responseAddUpdateDb={responseAddUpdateDb}
                cartParamData={cartParamData}
                carouselOriginState={carouselOriginState}
              />
            ))
          }
        </a>
      );
    }
  }
  // componente link
  if (
    slugLoftyEqualTo(component, 'basicLink') || slugLoftyEqualTo(component, 'aContainer')
    || slugLoftyEqualTo(component, 'hrefButton') || slugLoftyEqualTo(component, 'hamburgerItemLink')
  ) {
    if (component.pageWithParams && (component.withParams === 'local' || component.withParams === true)) {
      const urlRedirect = component.pageWithParams;
      const params = component.attributes.paramsPage;
      let res = urlRedirect;
      if (params) {
        params?.forEach((index) => {
          const comp = `:${index.name}`;
          res = res.replace(comp, index.value);
        });
        if (res.match(/:/g)) {
          res = res.replace(/:[a-zA-Z0-9_]+/g, 'null');
        }
      } else {
        const params = component.attributes?.hrefParams;
        params?.forEach((index) => {
          const comp = index.url;
          if (objectReader) {
            res = res.replace(comp, objectReader[index.param]);
          }
        });
        if (res.match(/:/g)) {
          res = res.replace(/:[a-zA-Z0-9_]+/g, 'null');
        }
      }
      return (
        <a
          target={component.attributes?.target}
          className={manageClasses(component)}
          id={component.attributes?.id}
          href={res}
        >
          {
        component.components?.map((item) => (
          <DynamicComponent
            idProject={idProject}
            component={item}
            dataEndpoints={dataEndpoints}
            params={params}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={idGetEndpoint}
            idPostEndpoint={idPostEndpoint}
            idPutEndpoint={idPutEndpoint}
            nameFiledReader={nameFiledReader}
            objectReader={objectReader}
            relationParam={relationParam}
            deletetokenApp={deletetokenApp}
            dataGenerate={dataGenerate}
            // plugin del calnedario
            activeMonth={activeMonth}
            changeLastMonth={changeLastMonth}
            changeNextMonth={changeNextMonth}
            listHours={listHours}
            now={now}
            selectDate={selectDate}
            changeHour={changeHour}
            submitCalendar={submitCalendar}
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            user={user}
            useCurrentUserAddDatabase={useCurrentUserAddDatabase}
            allowMultipleAttachment={allowMultipleAttachment}
            token={token}
            userInfo={userInfo}
            databaseSelectionForFields={databaseSelectionForFields}
            // STATES
            globalVariables={globalVariables}
            setGlobalState={setGlobalState}
            localState={localState}
            setLocalState={setLocalState}
            globalVars={globalVars}
            localVars={localVars}
            // Input With Variables
            inputStateVariable={inputStateVariable}
            // For Read Db with dbField data
            rootReaderData={rootReaderData}
            refurbishEndpoint={refurbishEndpoint}
            variableForEndpoint={variableForEndpoint}
            responseAddUpdateDb={responseAddUpdateDb}
            cartParamData={cartParamData}
            carouselOriginState={carouselOriginState}
          />
        ))
      }
        </a>
      );
    } else {
      return (
        <a
          target={component.attributes?.target}
          className={manageClasses(component)}
          id={component.attributes?.id}
          href={component.attributes?.href}
        >
          {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
        </a>
      );
    }
  }
  if (slugLoftyEqualTo(component, 'basicImage')) {
    return (
      <img
        className={manageClasses(component)}
        id={component.attributes?.id}
        src={component.attributes?.src}
        alt={component.attributes?.alt}
      />
    );
  }
  if (slugLoftyEqualTo(component, 'basicVideo')) {
    if (component.tagName === 'iframe') {
      if (component.provider === 'yt' || component.provider === 'ytnc' || component.provider === 'vi') {
        return (
          <div id={component.attributes?.id} className={manageClasses(component)}>
            <iframe
              title="Video Youtube"
              src={component.src}
              allowFullScreen={component.attributes?.allowfullscreen}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        );
      }
    }
    // console.log(component);
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        id={component.attributes?.id}
        className={manageClasses(component)}
        src={component.src}
        loop={component.loop}
        autoPlay={component.autoplay}
        muted={component.autoplay}
        controls={component.controls}
        playsInline={component.autoplay}
      />
    );
  }
  if (slugLoftyEqualTo(component, 'basicMap')) {
    return (
      <div id={component.attributes?.id} className={manageClasses(component)}>
        <iframe
          title="Mapa"
          className="gjs-no-pointer"
          style={{
            height: '100%',
            width: '100%',
            pointerEvents: 'all',
          }}
          frameBorder={component.attributes?.frameborder}
          src={component.src}
        />
      </div>
    );
  }
  // componente url
  if (slugLoftyEqualTo(component, 'hamburgerSideMenu')) {
    return (
      <ul id={component.attributes?.id} className={manageClasses(component)}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </ul>
    );
  }
  // componente li
  if (slugLoftyEqualTo(component, 'hamburgerLinksList')) {
    return (
      <li className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </li>
    );
  }

  // componente table
  if (componentTable({component})) {
    return (
      <table className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </table>
    );
  }

  // componente thead
  if (componentThead({component})) {
    return (
      <thead className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </thead>
    );
  }

  // componente tr
  if (componentTr({component})) {
    return (
      <tr className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </tr>
    );
  }

  // componente tbody
  if (componentTBody({component})) {
    if (slugLoftyEqualTo(component, 'calendarDaySection')) {
      return (
        <ItemCalendar
          component={component}
          activeMonth={activeMonth}
          addTokenApp={addTokenApp}
          addUserApp={addUserApp}
          dataEndpoints={dataEndpoints}
          idDeleteEndpoint={idDeleteEndpoint}
          idGetEndpoint={idGetEndpoint}
          idPostEndpoint={idPostEndpoint}
          idProject={idProject}
          idPutEndpoint={idPutEndpoint}
          nameFiledReader={nameFiledReader}
          objectReader={objectReader}
          params={params}
          relationParam={relationParam}
          token={token}
          changeLastMonth={changeLastMonth}
          changeNextMonth={changeNextMonth}
          now={now}
          selectDate={selectDate}
          listHours={listHours}
          changeHour={changeHour}
          submitCalendar={submitCalendar}
          deletetokenApp={deletetokenApp}
          dataGenerate={dataGenerate}
          user={user}
          userInfo={userInfo}
          databaseSelectionForFields={databaseSelectionForFields}
          // STATES
          globalVariables={globalVariables}
          setGlobalState={setGlobalState}
          localState={localState}
          setLocalState={setLocalState}
          globalVars={globalVars}
          localVars={localVars}
          // Input With Variables
          inputStateVariable={inputStateVariable}
          // For Read Db with dbField data
          rootReaderData={rootReaderData}
          refurbishEndpoint={refurbishEndpoint}
          variableForEndpoint={variableForEndpoint}
          responseAddUpdateDb={responseAddUpdateDb}
          cartParamData={cartParamData}
          carouselOriginState={carouselOriginState}
        />
      );
    }
    return (
      <tbody className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </tbody>
    );
  }

  // componente th
  if (componentTh({component})) {
    return (
      <th className={manageClasses(component)} id={component.attributes?.id} colSpan={component.attributes?.colspan}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </th>
    );
  }

  // componente td
  if (componentTd({component})) {
    return (
      <td className={manageClasses(component)} id={component.attributes?.id}>
        {
          component.components?.map((item) => (
            <DynamicComponent
              idProject={idProject}
              component={item}
              dataEndpoints={dataEndpoints}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </td>
    );
  }

  // ul List HTML
  if (componentOlList({component})) {
    return (
      <ul
        name={component.attributes?.name}
        className={manageClasses(component)}
        id={component.attributes?.id}
      >
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </ul>
    );
  }

  // Li component
  if (componentListItem({component})) {
    return (
      <li
        name={component.attributes?.name}
        className={manageClasses(component)}
        id={component.attributes?.id}
        style={{listStyleType: 'disc'}}
      >
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </li>
    );
  }

  // component Dynamic Image
  if (componentDynImage({component})) {
    const urlData = objectReader?.loftyUrl;
    if (urlData) {
      return (
        <img
          className={manageClasses(component)}
          id={component.attributes?.id}
          src={urlData}
          alt={component.attributes?.alt}
        />
      );
    }
    return null;
  }

  // Component Google Icons
  if (componentGoogleIcon({component})) {
    const searchForItems = component?.attributes?.googleIconSelection;
    if (!searchForItems) {
      return (
        <span className="material-icons" id={component.attributes?.id}>
          home
        </span>
      );
    }
    return (
      <span className="material-icons" id={component.attributes?.id}>
        {searchForItems}
      </span>
    );
  }

  // component Animated Scroll Container
  if (componentAnimatedScrollContainer({component})) {
    return (
      <div
        name={component.attributes?.name}
        className={manageClasses(component)}
        id={component.attributes?.id}
        data-aos={component.attributes?.['data-aos']}
        data-aos-duration={component.attributes?.['data-aos-duration']}
      >
        {
          component.components?.map((item) => (
            <DynamicComponent
              component={item}
              dataEndpoints={dataEndpoints}
              idProject={idProject}
              params={params}
              idDeleteEndpoint={idDeleteEndpoint}
              idGetEndpoint={idGetEndpoint}
              idPostEndpoint={idPostEndpoint}
              idPutEndpoint={idPutEndpoint}
              nameFiledReader={nameFiledReader}
              objectReader={objectReader}
              relationParam={relationParam}
              deletetokenApp={deletetokenApp}
              addTokenApp={addTokenApp}
              addUserApp={addUserApp}
              dataGenerate={dataGenerate}
              // plugin del calnedario
              activeMonth={activeMonth}
              changeLastMonth={changeLastMonth}
              changeNextMonth={changeNextMonth}
              listHours={listHours}
              now={now}
              selectDate={selectDate}
              changeHour={changeHour}
              submitCalendar={submitCalendar}
              user={user}
              useCurrentUserAddDatabase={useCurrentUserAddDatabase}
              allowMultipleAttachment={allowMultipleAttachment}
              token={token}
              idGetEnpointSwiper1={idGetEnpointSwiper1}
              idGetEnpointSwiper2={idGetEnpointSwiper2}
              idGetEnpointSwiper3={idGetEnpointSwiper3}
              relationParamData={relationParamData}
              userInfo={userInfo}
              databaseSelectionForFields={databaseSelectionForFields}
              // STATES
              globalVariables={globalVariables}
              setGlobalState={setGlobalState}
              localState={localState}
              setLocalState={setLocalState}
              globalVars={globalVars}
              localVars={localVars}
              // Input With Variables
              inputStateVariable={inputStateVariable}
              // For Read Db with dbField data
              rootReaderData={rootReaderData}
              refurbishEndpoint={refurbishEndpoint}
              variableForEndpoint={variableForEndpoint}
              responseAddUpdateDb={responseAddUpdateDb}
              cartParamData={cartParamData}
              carouselOriginState={carouselOriginState}
            />
          ))
        }
      </div>
    );
  }
  if (componentDynamicVideo({component})) {
    const sourceInfo = objectReader?.[component?.stringParams];
    if (sourceInfo) {
      if (sourceInfo.includes('youtu')) {
        return (
          <div id={component.attributes?.id} className={manageClasses(component)}>
            <iframe
              src={sourceInfo}
              title={component.attributes?.id}
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </div>
        );
      }
      return (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          controls
          className={manageClasses(component)}
          id={component.attributes?.id}
        >
          <source src={sourceInfo} type="video/mp4" />
        </video>
      );
    }
  }
  return (
    <div id={component.attributes?.id} className={manageClasses(component)}>
      {
        component.components?.map((item) => (
          <DynamicComponent
            idProject={idProject}
            component={item}
            dataEndpoints={dataEndpoints}
            params={params}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={idGetEndpoint}
            idPostEndpoint={idPostEndpoint}
            idPutEndpoint={idPutEndpoint}
            nameFiledReader={nameFiledReader}
            objectReader={objectReader}
            relationParam={relationParam}
            deletetokenApp={deletetokenApp}
            dataGenerate={dataGenerate}
            // plugin del calnedario
            activeMonth={activeMonth}
            changeLastMonth={changeLastMonth}
            changeNextMonth={changeNextMonth}
            listHours={listHours}
            now={now}
            selectDate={selectDate}
            changeHour={changeHour}
            submitCalendar={submitCalendar}
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            user={user}
            useCurrentUserAddDatabase={useCurrentUserAddDatabase}
            allowMultipleAttachment={allowMultipleAttachment}
            token={token}
            idGetEnpointSwiper1={idGetEnpointSwiper1}
            idGetEnpointSwiper2={idGetEnpointSwiper2}
            idGetEnpointSwiper3={idGetEnpointSwiper3}
            relationParamData={relationParamData}
            userInfo={userInfo}
            databaseSelectionForFields={databaseSelectionForFields}
            // STATES
            globalVariables={globalVariables}
            setGlobalState={setGlobalState}
            localState={localState}
            setLocalState={setLocalState}
            globalVars={globalVars}
            localVars={localVars}
            // Input With Variables
            inputStateVariable={inputStateVariable}
            // For Read Db with dbField data
            rootReaderData={rootReaderData}
            refurbishEndpoint={refurbishEndpoint}
            variableForEndpoint={variableForEndpoint}
            responseAddUpdateDb={responseAddUpdateDb}
            cartParamData={cartParamData}
            carouselOriginState={carouselOriginState}
          />
        ))
      }
    </div>
  );
}

export default DynamicComponent;
