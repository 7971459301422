import React, {useEffect, useState} from 'react';
import {
  MapContainer, TileLayer, Marker, Popup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import {manageClasses} from '../util/componentsUtil';

const customIcon = new L.Icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

function LeafMapComponent({
  component, maindata, dataGenerate,
}) {
  // const position = [11.7663, 11.67641];
  const [locationData, setLocationData] = useState([]);
  const [centerData, setCenterData] = useState(null);
  const getCenterData = (data) => {
    const latitudes = data.map((loc) => loc.coordinates.latitude);
    const longitudes = data.map((loc) => loc.coordinates.longitude);

    const avgLat = latitudes.reduce((sum, lat) => sum + lat, 0) / latitudes.length;
    const avgLng = longitudes.reduce((sum, lng) => sum + lng, 0) / longitudes.length;

    return [avgLat, avgLng];
  };

  useEffect(() => {
    // Search for Database Fields
    const getFields = dataGenerate?.fieldsDatabase.filter((index) => (
      index.idDatabase === component.dbSelect));
    const getCoordinateField = getFields?.find((index) => (index._id === component.fieldCoSelect));
    const getLabel = getFields?.find((index) => (index.isLabel));
    const SectionData = [];
    maindata?.data?.data.forEach((element) => {
      if (element[getCoordinateField?.name]) {
        const latitude = Number(element[getCoordinateField?.name].latitude);
        const longitude = Number(element[getCoordinateField?.name].longitude);
        SectionData.push({label: element[getLabel?.name] || 'Location', coordinates: {latitude, longitude}});
      }
    });
    const centerinfo = getCenterData(SectionData);
    setCenterData(centerinfo);
    setLocationData(SectionData);
  }, [maindata]);

  return (
    <div
      id={component.attributes?.id}
      className={manageClasses(component)}
    >
      {
        centerData && locationData.length > 0 && (
          <MapContainer center={centerData} zoom={13} style={{height: '400px', width: '100%'}}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {locationData?.map((location) => (
              <Marker
                position={[location.coordinates.latitude, location.coordinates.longitude]}
                icon={customIcon}
              >
                <Popup>{location?.label}</Popup>
              </Marker>
            ))}
          </MapContainer>
        )
      }

    </div>
  );
}

// FInd Center

export default LeafMapComponent;
