/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/function-component-definition */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, {useState, useEffect} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {manageClasses} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';

function DynamicInfiniteCarousel({
  items,
  responsiveConfig,
  id,
  updateMiddleItem,
  originModel,
  // dynamicComponent data
  component, dataEndpoints, idGetEndpoint,
  // eslint-disable-next-line no-unused-vars
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  idDeleteEndpoint, relationParam, token,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint,
  dataGenerate, colorButton,
}) {
  /*
    const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 4000, min: 3000},
      items: 5,
    },
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 1024, min: 464},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };
  */
  // const [middleItem, setMiddleItem] = useState(null);
  const [currentSlide, setCurrentSLide] = useState(0);
  const [middleIndex, setMiddleIndex] = useState(0);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  function handleSetItem(direction, navigateFunc) {
    let useVisible = 3; // default
    if (screenSize > 3000) {
      useVisible = responsiveConfig.superLargeDesktop.items;
    } else if (screenSize < 3000 && screenSize > 1024) {
      useVisible = responsiveConfig.desktop.items;
    } else if (screenSize < 1024 && screenSize > 464) {
      useVisible = responsiveConfig.tablet.items;
    } else if (screenSize < 464) {
      useVisible = 1;
    }
    const visibleItemsCount = useVisible; // Adjust based on actual current screen width
    const totalItems = items.length;

    // console.log(visibleItemsCount, totalItems);
    const newSlide = (currentSlide + direction + totalItems) % totalItems;
    setCurrentSLide(newSlide);

    const middleSeeker = (newSlide + Math.floor(visibleItemsCount / 2)) % items.length;

    const newMiddleItem = items[middleSeeker];
    // setMiddleItem(newMiddleItem);
    setMiddleIndex(middleSeeker);
    if (navigateFunc) {
      navigateFunc();
    }
    // Update the parent component with the middle item
    updateMiddleItem(id, newMiddleItem);
  }

  const handleSlideChange = (direction, navigateFunc) => {
    handleSetItem(direction, navigateFunc);
  };

  useEffect(() => {
    handleSetItem(0);
  }, [items]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // BUTTON GROUP
  const CustomButtonGroup = ({next, previous}) => (
    <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px'}}>
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        onClick={() => handleSlideChange(-1, previous)}
        type="button"
        style={{
          background: colorButton || 'rgba(0, 0, 0, 0.5)',
          '--hover-opacity': '0.3',
        }}
      />
      <button
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        onClick={() => handleSlideChange(1, next)}
        type="button"
        style={{
          background: colorButton || 'rgba(0, 0, 0, 0.5)',
          '--hover-opacity': '0.3',
        }}
      />
    </div>
  );

  return (
    <div className={manageClasses(originModel)} id={originModel.attributes?.id}>
      <Carousel
        responsive={responsiveConfig}
        infinite
        arrows={false}// Hide default arrows
        customButtonGroup={<CustomButtonGroup />}
        // renderButtonGroupOutside
        keyBoardControl={false}
        draggable={false}
        swipeable={false}
      >
        {items?.map((item, index) => {
          if (index === middleIndex) {
            return (
              <div key={index} id={component.attributes?.id} className={manageClasses(component)}>
                {
              component.components?.map((value) => (
                <DynamicComponent
                  idProject={idProject}
                  component={value}
                  dataEndpoints={dataEndpoints}
                  objectReader={item}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  token={token}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  // Input With Variables
                  // inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={item}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  dataGenerate={dataGenerate}
                  carouselOriginState="show"
                />
              ))
            }
              </div>
            );
          }
          return (
            <div key={index} id={component.attributes?.id} className={manageClasses(component)}>
              {
              component.components?.map((value) => (
                <DynamicComponent
                  idProject={idProject}
                  component={value}
                  dataEndpoints={dataEndpoints}
                  objectReader={item}
                  params={params}
                  idDeleteEndpoint={idDeleteEndpoint}
                  idGetEndpoint={idGetEndpoint}
                  idPostEndpoint={idPostEndpoint}
                  idPutEndpoint={idPutEndpoint}
                  nameFiledReader={nameFiledReader}
                  relationParam={relationParam}
                  addTokenApp={addTokenApp}
                  addUserApp={addUserApp}
                  token={token}
                  // STATES
                  globalVariables={globalVariables}
                  setGlobalState={setGlobalState}
                  localState={localState}
                  setLocalState={setLocalState}
                  // Input With Variables
                  // inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
                  rootReaderData={item}
                  refurbishEndpoint={refurbishEndpoint}
                  variableForEndpoint={variableForEndpoint}
                  dataGenerate={dataGenerate}
                  carouselOriginState="hide"
                />
              ))
            }
            </div>
          );
        })}
      </Carousel>
      {/* {middleItem && (
        <div>
          <h2>
            Middle Item of Carousel
            {id}
          </h2>
          <p>{JSON.stringify(middleItem)}</p>
        </div>
      )} */}
    </div>
  );
}

export default DynamicInfiniteCarousel;
