/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import React, {useEffect, useState} from 'react';
import {getDataGoogle, loginWithGoogle} from 'api-lofty';
import {GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';
import {manageClasses} from '../util/componentsUtil';
import DynamicComponent from './DynamicComponent';

function GoogleLoginComponent({
  component, dataEndpoints, idGetEndpoint,
  objectReader, nameFiledReader, idPostEndpoint,
  idProject, addTokenApp, addUserApp, params, idPutEndpoint,
  // eslint-disable-next-line no-unused-vars
  idDeleteEndpoint, relationParam, token, rootReaderData,
  localState, globalVariables, setLocalState, setGlobalState,
  refurbishEndpoint, variableForEndpoint, dataGenerate,
}) {
  const [hasLoadedData, setHasLoadedData] = useState(true);
  const [googleIdClient, setGoogleIdClient] = useState('');
  useEffect(async () => {
    try {
      const filterGoogleInfo = dataGenerate?.userProjects?.find((index) => (index.type === 'google'));
      if (filterGoogleInfo) {
        const googleInfoProject = await getDataGoogle({idProject, idUserProject: filterGoogleInfo?._id});
        setGoogleIdClient(googleInfoProject.clientId);
      }
    } catch (error) {
      setHasLoadedData(false);
    }
  }, []);

  if (!hasLoadedData) {
    return null;
  }

  async function loginSuccesAction(response) {
    try {
      const tokenGoogle = response.credential;
      await loginWithGoogle({idProject, token, tokenId: tokenGoogle});
      const traitAction = component?.actionRegister;
      if (traitAction === 'message') {
        // eslint-disable-next-line no-alert
        alert(component?.alertMessage);
      } else if (traitAction === 'redirect') {
        if (component?.redirectPage) {
          window.location.href = component?.redirectPage;
        }
      }
    } catch (error) {
      //
    }
  }

  return (
    <GoogleOAuthProvider
      clientId={googleIdClient}
    >
      <div className={manageClasses(component)} id={component.attributes?.id}>
        {
        component.components?.map((value) => (
          <DynamicComponent
            idProject={idProject}
            component={value}
            dataEndpoints={dataEndpoints}
            objectReader={objectReader}
            params={params}
            idDeleteEndpoint={idDeleteEndpoint}
            idGetEndpoint={idGetEndpoint}
            idPostEndpoint={idPostEndpoint}
            idPutEndpoint={idPutEndpoint}
            nameFiledReader={nameFiledReader}
            relationParam={relationParam}
            addTokenApp={addTokenApp}
            addUserApp={addUserApp}
            token={token}
                  // STATES
            globalVariables={globalVariables}
            setGlobalState={setGlobalState}
            localState={localState}
            setLocalState={setLocalState}
                  // Input With Variables
                  // inputStateVariable={inputStateVariable}
                  // For Read Db with dbField data
            rootReaderData={rootReaderData}
            refurbishEndpoint={refurbishEndpoint}
            variableForEndpoint={variableForEndpoint}
          />
        ))
      }
        <GoogleLogin
          onSuccess={loginSuccesAction}
          useOneTap
        />
      </div>
    </GoogleOAuthProvider>
  );
}

export default GoogleLoginComponent;
